import { Box } from "@maysoft/common-component-react";
import { ICodename } from "commons/interfaces";
import { IBookingDetail } from "components/Booking/useDataRequestBooking.hook";
import { IBookingDetailPopupUser, IService } from "../../../interfaces";
import UserAccordionForAdditionalServices from "../UserAccordionForAdditionalServices";
import Strings from "constants/strings";

const EditModeFlightBookingDetailAdditionalServicesPopup = ({
    renderBookingDetailExtraContent,
    renderSummary,
    renderPaymentContent,
    onAddService,
    onRemoveService,
    onUpdateService,
    users,
    errorsByUserId,
    serviceCodenames,
    servicesByUserIdMap = { },
}: {
    renderBookingDetailExtraContent: (args: { allowNote: boolean }) => React.ReactNode;
    renderSummary: () => React.ReactNode;
    renderPaymentContent: ({ title }: { title: string }) => React.ReactNode;
    onAddService?: (user: IBookingDetailPopupUser, detail: IBookingDetail, segmentIndex: number) => void;
    onRemoveService?: (service: IService, user: IBookingDetailPopupUser, detail: IBookingDetail) => void;
    onUpdateService?: (newService: IService, keyChanged: keyof IService) => void;
    users: IBookingDetailPopupUser[];
    errorsByUserId: Partial<Record<string, Partial<Record<string, string>>>>;
    serviceCodenames: ICodename[];
    servicesByUserIdMap: Record<string, IService[]>;
}) => {
    return (
        <Box display="flex" gap={2} flexDirection="column">
            {users.map((user) => {
                return (
                    <UserAccordionForAdditionalServices
                        key={user.id}
                        data={user}
                        errors={errorsByUserId[user.id]}
                        services={servicesByUserIdMap[user.id]}
                        serviceCodenames={serviceCodenames}
                        onAddService={onAddService}
                        onRemoveService={onRemoveService}
                        onUpdateService={onUpdateService}
                    />
                );
            })}

            <Box px={2}>
                {renderBookingDetailExtraContent({ allowNote: false })}
            </Box>
            <Box display="flex" gap={2} px={2} py={1} border='1px solid #D9D9D9' flexDirection="column">
                {renderPaymentContent({ title: Strings.Common.PAYMENT_TYPE })}
                {renderSummary()}
            </Box>
        </Box>
    )
}

export default EditModeFlightBookingDetailAdditionalServicesPopup;