import moment from "moment";
import { useMemo, useState } from "react";
import { Grid, } from "@mui/material";
import { Box, Button, Typography } from "@maysoft/common-component-react";

import Helpers from "commons/helpers";
import useDataBookingOrder from "../useDataBookingOrder.hook";

import { BookingOrderType, PaymentStatus } from "constants/enum";
import { IDetailBooking } from "components/Booking/useDataRequestBooking.hook";
import ConfirmRefundPopup from "components/Booking/BookingDetailContainer/Popup/BookingDetailConfirmRefundPopup";



const CardBasicBookingOrder = (props: {
    refundable?: boolean;
    onFetchData?: () => Promise<void>;
    index: number;
    orderCode: string;
    orderDate: string;
    paymentStatus: number;
    orderDetailType: number;
    dataBooking: IDetailBooking;
}) => {
    const {
        listOrderDetailType,
        getPaymentStatusByBookingOrder,
    } = useDataBookingOrder();
    const newOrderDate = useMemo(() => (Helpers.getDateValue(props.orderDate)), [props.orderDate]);
    const newDataPaymentStatus = useMemo(() => {
        const newdata = getPaymentStatusByBookingOrder({
            bookingOrderType: BookingOrderType.Main,
            paymentStatus: Number(props?.dataBooking?.paymentStatus),
            confirmStatusBooking: Number(props?.dataBooking?.confirmStatus),
        });
        switch (props?.dataBooking?.paymentStatus) {
            case PaymentStatus.WaitingPartialRefund:
            case PaymentStatus.WaitingRefund:
                newdata.color = "error";
                break;
            case PaymentStatus.PartialRefunded:
            case PaymentStatus.Refunded:
                newdata.color = "info";
                newdata.title = "Đã hoàn tiền";
                break;
        }

        return newdata;
    }, [
        getPaymentStatusByBookingOrder,
        props?.dataBooking?.paymentStatus,
        props?.dataBooking?.confirmStatus,
    ]);

    const [isRefundPopupVisible, setIsRefundPopupVisible] = useState<boolean>(false);

    const renderBookingOrderDetail = () => {
            return (<Box display="flex" alignItems="center" justifyContent="space-between" flexWrap="wrap" gap={1}> 
                    <Typography variant="button">
                        {"Dịch vụ: "}
                        {listOrderDetailType?.find(el => el.code === props.orderDetailType)?.name || "-"}
                    </Typography>
                    {!Helpers.isNullOrEmpty(props.dataBooking?.externalBookingCode) &&
                        <Typography variant="button">
                            {"Booking Code: "}
                            {props.dataBooking?.externalBookingCode || ""}
                        </Typography>
                    }
                    <Typography variant="button">
                        {"Trạng thái: "}
                        {
                            <Typography variant="button" color={newDataPaymentStatus?.color as any} fontWeight="bold">
                                {newDataPaymentStatus?.title || "-"}
                            </Typography>
                        }
                    </Typography>
                    {props.refundable && <Button
                        variant="outlined"
                        onClick={() => setIsRefundPopupVisible(true)}
                    >
                        Xác nhận hoàn tiền
                    </Button>}
            </Box>)
    }

    return (
        <>
            {isRefundPopupVisible && <ConfirmRefundPopup
                bookingOrderId={props.dataBooking?.bookingOrderId} 
                visible={isRefundPopupVisible}
                onClose={() => setIsRefundPopupVisible(false)}
                message={"Bạn có muốn hoàn tiền cho đơn hàng này không?"}
                onCompleted={async () => {
                    setIsRefundPopupVisible(false);
                    await props.onFetchData();
                }}
            />}
            <Box display="flex" flexDirection="column" gap={1}>
                <Box gap={1} display={"flex"} justifyContent="space-between" flexWrap="wrap">
                    <Typography variant="button" fontWeight="bold">
                        {"Mã đơn hàng: "}
                        {props?.orderCode || "-"}
                    </Typography>
                    <Typography variant="button">
                        {"Thời gian tạo: "}
                        {newOrderDate ? moment(newOrderDate).format("HH:mm:ss DD/MM/YYYY") : "-"}
                    </Typography>
                </Box>
            
                {renderBookingOrderDetail()} 
            </Box>
        </>
    )
};

export default CardBasicBookingOrder;