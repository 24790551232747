import { Typography, Box } from "@maysoft/common-component-react";
import Strings from "constants/strings";
import moment from "moment";

const OperationHistorySectionTitle = (props: { title: string, index?: number, updateTime?: number; fullName: string }) => {
    const { title, index, updateTime, fullName } = props;

    const renderUpdaterText = () => {
        if (updateTime && fullName) {
            return <Typography variant="caption">({Strings.formatString(Strings.BOOKING.AMEND_BOOKING.UPDATE_BOOKING_BOTH_UPDATE_USER_AND_UPDATE_TIME_AVAILABLE, fullName, updateTime ? `lúc ${moment(updateTime * 1000).format("HH:mm DD/MM/YYYY")}` : undefined)})</Typography>
        }
        if (updateTime && !fullName) {
            return <Typography variant="caption">({Strings.formatString(Strings.BOOKING.AMEND_BOOKING.UPDATE_BOOKING_ONLY_UPDATE_TIME_AVAILABLE, updateTime ? `lúc ${moment(updateTime * 1000).format("HH:mm DD/MM/YYYY")}` : undefined)})</Typography>
        }
        if (!updateTime && fullName) {
            return <Typography variant="caption">({Strings.formatString(Strings.BOOKING.AMEND_BOOKING.UPDATE_BOOKING_ONLY_UPDATE_USER_AVAILABLE, fullName)})</Typography>
        }

        return null;
    }

    return <Box display="flex" flexDirection="row" gap={2} alignItems="center">
        <Typography variant="button" fontWeight="bold" color="primary">{index ? `${index}. ` : ""}{title}</Typography>
        {renderUpdaterText()}
    </Box>
}

export default OperationHistorySectionTitle;