import { Box, Typography } from "@maysoft/common-component-react";
import { BookingHelpers } from "commons/bookingHelpers";
import Helpers from "commons/helpers";
import { ICodename } from "commons/interfaces";
import { IBookingDetail } from "components/Booking/useDataRequestBooking.hook";
import ContentBox from "../../components/ContentBox";
import { IService } from "../../../interfaces";
import ConfirmAllChangesPopup from "../ConfirmAllChangesPopup";
import Strings from "constants/strings";
import Constants from "constants/index";
import moment from "moment";
import TextWithPrice from "../../components/TextWithPrice";

const ConfirmModeFlightBookingDetailAdditionalServicesPopup = ({
    expireTime,
    renderSummary,
    currency,
    data,
    serviceCodenames,
    paymentMethodName,
}: {
    expireTime: number,
    renderSummary: (args: { showDivider: boolean }) => React.ReactNode;
    currency: string;
    data: { userId: string, fullName: string, details: (IBookingDetail & { services: IService[] })[], }[];
    serviceCodenames: ICodename[];
    paymentMethodName: string;
}) => {
    const totalServices = data.reduce((prev, c) => prev + c.details.reduce((prev, c) => prev + c.services.length, 0), 0);
    if (totalServices === 0) {
        return <Box
            display={"flex"} 
            alignItems={"center"}
            justifyContent={"center"}
            paddingTop={6}
            paddingBottom={4}
        >
            <Typography variant="button">
                {Strings.BOOKING.ADDITION_SERVICE_NO_SERVICE_TO_ADD}
            </Typography>
        </Box>;
    }


    return (
        <ConfirmAllChangesPopup
            note={Strings.BOOKING.ADDITION_SERVICE_CONFIRM_NOTE}
            renderContent={() => (
                <>
                    <Box
                        display="flex"
                        flexDirection="column"
                        gap={1}
                    >
                        <Typography variant="button" component="p">
                            {Strings.BOOKING.ADDITION_SERVICE_CONFIRM_SUMMARY_TITLE}
                        </Typography>
                        {data.map(({ userId, fullName, details }, index) => {
                            return  <Box
                                        key={userId}
                                        display="flex"
                                        flexDirection="column"
                                        gap={1}
                                    >
                                    <Typography
                                        variant="button"
                                        component="span"
                                    >
                                        {Strings.BOOKING.FLIGHT_PASSENGER}&nbsp;<strong>{fullName}</strong>&nbsp;:
                                    </Typography>
                                    {details.map((detail) => {
                                        const { departPlace, arrivalPlace, carrierMarketing } = BookingHelpers.getFlightExtraInfo(detail, userId);

                                        return (
                                            <Box key={detail.id}>
                                                <Typography
                                                    variant="button"
                                                    component="span"
                                                    pl={1}
                                                >
                                                    {Strings.BOOKING.FLIGHT} {departPlace} - {arrivalPlace}, hãng {carrierMarketing.name}
                                                </Typography>
                                                <Box component="ul" pl={4}>
                                                    {detail.services.map((service) => {
                                                        const name = serviceCodenames.find((c) => c.code === service.itemId)?.name;
                                                        return (<Typography variant="button" component="li" key={service._id}>
                                                                {name}&nbsp;({service.description})&nbsp;&nbsp;-&nbsp;&nbsp;
                                                                <strong>
                                                                    {Helpers.formatCurrency(service.unitPrice || 0)} {currency}
                                                                </strong>
                                                            </Typography>
                                                        )
                                                    })}
                                                </Box>
                                                
                                            </Box>
                                        )
                                    })}
                                    
                                </Box>
                            })}
                        
                        {/* <div>
                            <Typography
                                variant="button"
                                component="span"
                            >
                                {Strings.Common.PAYMENT_TYPE}:
                            </Typography>
                            &nbsp;
                            <Typography
                                variant="button"
                                component="span"
                                fontWeight="bold"
                            >
                                {paymentMethodName}
                            </Typography>
                        </div> */}
                    </Box>
                    <Box border="1px solid #D9D9D9" px={1} py={0.5} borderRadius="4px">
                        {renderSummary({ showDivider: false })}
                        <TextWithPrice
                            text={Strings.BOOKING.CONFIRM_PERIOD}
                            price={moment(expireTime * 1000).format(Constants.DateFormat.HHMM_DDMMYYYY)}
                        />
                    </Box>

                    {/* <Typography
                        mt={2}
                        variant="button"
                        color="secondary"
                    >
                        {Strings.BOOKING.CONFIRM_PERIOD}:&nbsp;
                        {moment(expireTime * 1000).format(Constants.DateFormat.HHMM_DDMMYYYY)}
                    </Typography> */}
                </>
            )}
        />
    )
}

export default ConfirmModeFlightBookingDetailAdditionalServicesPopup;