import {
    Box,
    Modal,
} from "@maysoft/common-component-react";
import { useCallback, useMemo, useState } from "react";
import { ITransferBookingDetailViewModel } from "../interfaces";
import useBookingDetailSubmit, { IUseBookingDetailPopupSubmitProps } from "./hooks/useBookingDetailSubmit";
import { CabinClass, PaymentType } from "constants/enum";
import useLoadBookingDetailServiceFee from "./hooks/useLoadServiceFee";
import useCabinClassCodenames from "hooks/useCabinClassCodenames";
import { array, number, object, string } from "yup";
import Helpers from "commons/helpers";
import useAirlineCodenames from "hooks/useAirlineCodenames";
import ConfirmModeFlightBookingDetailTransferFlightPopup from "./containers/TransferFlight/ConfirmModeFlightBookingDetailTransferFlightPopup";
import EditModeFlightBookingDetailTransferFlightPopup from "./containers/TransferFlight/EditModeFlightBookingDetailTransferFlightPopup";
import { useAddMoreBookingServiceContext } from "providers/addBookingServiceProvider";
import Strings from "constants/strings";
import usePaymentMethodCodenames from "hooks/usePaymentMethodCodenames";


interface TransferFlightPopupProps extends Pick<IUseBookingDetailPopupSubmitProps, "onCompleted"> {
    visible: boolean;
    onClose: () => void;
}

const BookingDetailTransferFlightPopup = (props: TransferFlightPopupProps) => {
    const transferRequestSchema = array().of(object().shape({
        _id: string().required(),
        departTime: number().required(),

        arrivalTime: number().required(),
        cabinClass: string().oneOf(Object.values(CabinClass)).required(),
        baggage: string().required(),
        // reservationCode: string().required(),
        flightNumber: string().required(),
        ticketNumber: string().nullable(),
        seatNumber: string().nullable(),

        arrivalPlace: string().required(),
        departPlace: string().required(),
        airline: string().required(),

        unitPrice: number().min(0).required(),
        extraFeeOtherFee: number().min(0).nullable(),
        extraFeeTransferFee: number().min(0).required(),
    }));

    const paymentTypeCodenames = usePaymentMethodCodenames();
    const [mode, setMode] = useState<"edit" | "confirm">("edit");
    const { currency, data, users } = useAddMoreBookingServiceContext();

    const { loading: isSubmitLoading, onTransferSubmit, renderLoadingContent, renderPaymentContent, commonProperties: { paymentType, expireTime }, renderBookingDetailExtraContent } = useBookingDetailSubmit({ onCompleted: props.onCompleted, data });
    const { totalFee, totalPaymentFee, loading: isLoadingServiceFee, serviceFeeByBookingDetailId, onCalculateServiceFeeForTransfer, onRecalculateServiceFeeByPaymentMethod } = useLoadBookingDetailServiceFee(data);
    const [transferRequests, setTransferRequests] = useState<ITransferBookingDetailViewModel[]>([]);
    const checkedTransferRequest = useMemo(() => {
        return transferRequests.filter(r => r.checked);
    }, [transferRequests])
    const [transferRequestErrors, setTransferRequestErrors] = useState<Record<string, { [key: string]: string | undefined }> | null>(null);
    const totalTransferUnitPrice = checkedTransferRequest.reduce((prev, curr) => prev + (curr.unitPrice || 0), 0); // chênh lệch vé
    const totalExtraTransferFee = checkedTransferRequest.reduce((prev, curr) => prev + (curr.extraFeeTransferFee || 0), 0); // phí đổi vé
    const totalExtraOtherFee = checkedTransferRequest.reduce((prev, curr) => prev + (curr.extraFeeOtherFee || 0), 0); // phí khác
    const totalTransferAmount = totalTransferUnitPrice + totalExtraTransferFee + totalExtraOtherFee;
    const paymentAmount = totalTransferAmount + totalFee + totalPaymentFee;

    // codenames
    const cabinClassCodenames = useCabinClassCodenames();
    const { data: airlines } = useAirlineCodenames();

    const validateTransferRequests = async (requests: ITransferBookingDetailViewModel[] = checkedTransferRequest) => {
        const matchSchemaRequests = requests.map(r => ({
            ...r,
            arrivalPlace: r.arrivalPlaceObj?.code,
            departPlace: r.departPlaceObj?.code,
            airline: r.airlineObj?.code,
        }))
        try {
            await transferRequestSchema.validate(matchSchemaRequests, { abortEarly: false });
            return null;
        } catch (e: any) {
            if (e.name === "ValidationError") {
                const { value: values, inner: inners } = e;
                const errors: typeof transferRequestErrors = { };
                inners.forEach(
                    (curr: any) => {
                        const path = curr.path;
                        const firstMatchIndex = path.match(/\d+/)?.[0];
                        const relevantValue = values[firstMatchIndex] as ITransferBookingDetailViewModel;

                        if (relevantValue) {
                            errors[relevantValue._id] = errors[relevantValue._id] ?? {};
                            const newPath = path.replace(
                                `[${firstMatchIndex}]`,
                                relevantValue._id
                            );
                            errors[relevantValue._id][newPath] = curr.errors[0];
                        }
                    });

                return errors;
            }
        }
    }
    const handleChangeToConfirmMode = async () => {
        try {
            const transferRequestErrors = await validateTransferRequests();
            if (transferRequestErrors) {
                setTransferRequestErrors(transferRequestErrors);
                return;
            }

            setMode("confirm");
        } catch (e) {
            const err = Helpers.renderExceptionError(e);
            Helpers.showAlert(err, "error");
        }
    }
    const handleChangeValue = useCallback((newTransferRequest: ITransferBookingDetailViewModel, key: keyof ITransferBookingDetailViewModel) => {
        const calculateSerivceFeeArgs = {
            userId: newTransferRequest.userId,
            detailId: newTransferRequest.bookingDetailId,
            checked: newTransferRequest.checked,
            quantity: 1,
            _id: newTransferRequest._id,
            unitPrice: newTransferRequest.unitPrice ?? 0,
            otherFee: newTransferRequest.extraFeeOtherFee ?? 0,
            fee: newTransferRequest.extraFeeTransferFee
        };
        let pure = true;
        let newData = [] as ITransferBookingDetailViewModel[];
        setTransferRequests(old => {
            newData = old;
            const index = old.findIndex(x => x._id === newTransferRequest._id);
            if (index === -1) {
                newData = [...old, newTransferRequest];
            } else {
                pure = newTransferRequest[key] === old[index][key];
                newData = old.map((x, i) => i === index ? newTransferRequest : x);
            }
            return newData;
        });

        // calculate totalTransferAmount
        const newCheckedTransferRequest = newData.filter(r => r.checked);
        const totalTransferUnitPrice = newCheckedTransferRequest.reduce((prev, curr) => prev + (curr.unitPrice || 0), 0); // chênh lệch vé
        const totalExtraTransferFee = newCheckedTransferRequest.reduce((prev, curr) => prev + (curr.extraFeeTransferFee || 0), 0); // phí đổi vé
        const totalExtraOtherFee = newCheckedTransferRequest.reduce((prev, curr) => prev + (curr.extraFeeOtherFee || 0), 0); // phí khác
        const totalTransferAmount = totalTransferUnitPrice + totalExtraTransferFee + totalExtraOtherFee;
        if (key === "checked") {
            // onCalculateServiceFeeForTransfer(calculateSerivceFeeArgs, paymentType, totalTransferAmount);
            setTransferRequestErrors(old => {
                delete old?.[newTransferRequest._id];
                return { ...old };
            })
        }
        if (["unitPrice", "extraFeeOtherFee", "extraFeeTransferFee"].includes(key)) {
            if ([
                PaymentType.NinePayATM,
                PaymentType.NinePayCollection,
                PaymentType.NinePayCredit,
                PaymentType.NinePayCreditInternational,
            ].includes(paymentType) && !pure) {
                // onCalculateServiceFeeForTransfer(calculateSerivceFeeArgs, paymentType, totalTransferAmount);
            }
            setTransferRequestErrors(old => {
                delete old?.[newTransferRequest._id]?.[`${newTransferRequest._id}.${key}`];
                return { ...old };
            })
        }
    }, [paymentType])

    const renderPaymentContentAndRecalculateServiceFee = useCallback(({ title }: { title: string }) => {
        return renderPaymentContent({
            title,
            // onChangePaymentType: (paymentType: PaymentType) => {
            //     onRecalculateServiceFeeByPaymentMethod(paymentType, totalTransferAmount);
            // },
            allowChangePaymentType: false,
        });
    }, [renderPaymentContent])
    const renderContent = () => {
        const submitLoadingShown = isSubmitLoading;

        const editModeShown = !submitLoadingShown && mode === "edit";

        const confirmModeShown = !submitLoadingShown && mode === "confirm";
        return <Box>
            <Box sx={{
                display: { xs: submitLoadingShown ? "block" : "none" },
            }}>
                {renderLoadingContent()}
            </Box>

            <Box sx={{
                display: { xs: editModeShown ? "block" : "none" },
            }}>
                <EditModeFlightBookingDetailTransferFlightPopup
                    data={data}
                    handleChangeValue={handleChangeValue}

                    users={users}
                    airlines={airlines}    
                    cabinClassCodenames={cabinClassCodenames}

                    isLoadingServiceFee={isLoadingServiceFee}
                    renderPaymentContent={renderPaymentContentAndRecalculateServiceFee}

                    amount={paymentAmount}
                    currency={currency}
                    // serviceFee={totalFee}
                    // paymentFee={totalPaymentFee}
                    totalTransferPrice={totalTransferAmount}
                    transferRequests={transferRequests}
                    transferRequestErrors={transferRequestErrors}
                    renderBookingDetailExtraContent={renderBookingDetailExtraContent}
                />
            </Box>

            <Box sx={{
                display: { xs: confirmModeShown ? "block" : "none" },
            }}>
                <ConfirmModeFlightBookingDetailTransferFlightPopup
                    expireTime={expireTime}
                    users={users}
                    cabinClassCodenames={cabinClassCodenames}

                    checkedTransferRequest={checkedTransferRequest} 

                    amount={paymentAmount}
                    currency={currency}
                    serviceFee={totalFee}
                    paymentFee={totalPaymentFee}
                    totalTransferPrice={totalTransferAmount}
                    paymentTypeName={paymentTypeCodenames.find(({ code }) => code === paymentType).name}
                />
            </Box>
        </Box>
    };

    const getActions = () => {
        if (isSubmitLoading) {
            return {
                hasActionButton: false,
                title: Strings.Common.PROCESSING,
            };
        }
        if (mode === "edit") {
            return {
                onAction: handleChangeToConfirmMode,
                hasActionButton: true,
                buttonAction: Strings.BOOKING.CREATE_ADDITION_SERVICE_ORDER,
                title:  Strings.BOOKING.ADD_SERVICE_TRANSFER_FLIGHT,
                onClickCloseIcon: props.onClose,
            };
        }

        const numOfTransferFlights = checkedTransferRequest.length;
        if (mode === "confirm") {
            return {
                onAction: numOfTransferFlights >= 1 ? () =>  onTransferSubmit({
                    amount: paymentAmount,
                    paymentFee: totalPaymentFee,
                    serviceFeeByBookingDetailId,
                    transferItems: checkedTransferRequest
                }) : undefined,
                hasActionButton: true,
                buttonAction: Strings.Common.CONFIRM,
                closeButton: Strings.BOOKING.POPUP_GO_BACK_BUTTON,
                title: Strings.BOOKING.TRANSFER_POPUP_CONFIRM_TITLE,
                onClickCloseIcon: props.onClose,
                onClose: () => setMode("edit"),
            };
        }

        return {};
    };

    return (
        <Modal
            {...getActions()}
            fullWidth
            maxWidth="md"
            visible={props.visible}
        >
            {renderContent()}
        </Modal>
    );
};

export default BookingDetailTransferFlightPopup;