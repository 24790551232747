import {
    Box,
    Modal,
} from "@maysoft/common-component-react";
import { Divider } from "@mui/material";
import { useCallback, useMemo, useState } from "react";

import Helpers from "commons/helpers";
import { array, number, object, string } from "yup";
import TextWithPrice from "./components/TextWithPrice";
import {
    IBookingDetailPopupUser,
    IService,
} from "../interfaces";
import { IBookingDetail } from "components/Booking/useDataRequestBooking.hook";
import useLoadBookingDetailServiceFee from "./hooks/useLoadServiceFee";
import useBookingDetailSubmit, { IUseBookingDetailPopupSubmitProps } from "./hooks/useBookingDetailSubmit";
import EditModeFlightBookingDetailAdditionalServicesPopup from "./containers/AdditionalServices/EditModeFlightBookingDetailAdditionalServicesPopup";
import useAdditionalServiceCodenames from "./hooks/useAdditionalServiceCodenames";
import usePaymentMethodCodenames from "hooks/usePaymentMethodCodenames";
import ConfirmModeFlightBookingDetailAdditionalServicesPopup from "./containers/AdditionalServices/ConfirmModeFlightBookingDetailAdditionalServicesPopup";
import { useAddMoreBookingServiceContext } from "providers/addBookingServiceProvider";
import { ICodename } from "commons/interfaces";
import Strings from "constants/strings";
import { BookingHelpers } from "commons/bookingHelpers";
import { PaymentType } from "constants/enum";

const servicesSchema = array().of(
    object().shape({
        _id: string().nullable(),
        userId: string().nullable(),
        bookingDetailId: string().nullable(),
        bookingId: string().nullable(),
        itemId: string().required("Vui lòng chọn loại dịch vụ"),
        description: string().required("Vui lòng nhập mô tả"),
        unitPrice: number().required("Vui lòng nhập giá tiền"),
    })
);

interface IBookingDetailAdditionalServicesPopupProps extends Pick<IUseBookingDetailPopupSubmitProps, "onCompleted"> {
    visible: boolean;
    onClose: () => void;
    itemCodenames: ICodename[];
    paymentMethodCodenames: ICodename[];
}
const BookingDetailAdditionalServicesPopup = (props: Omit<IBookingDetailAdditionalServicesPopupProps, "itemCodenames" | "paymentMethodCodenames">) => {
    const itemCodenames = useAdditionalServiceCodenames();
    const paymentMethodCodenames = usePaymentMethodCodenames();
    return props.visible && (<Content {...props} itemCodenames={itemCodenames} paymentMethodCodenames={paymentMethodCodenames} />);
};
const Content = (props: IBookingDetailAdditionalServicesPopupProps) => {
    const [mode, setMode] = useState<"edit" | "confirm">("edit");
    const { currency, data, users, getBookingDetailsByUserId } = useAddMoreBookingServiceContext();

    const { loading: isSubmitLoading, onAddAdditionServicesSubmit, renderLoadingContent, renderPaymentContent, renderBookingDetailExtraContent, commonProperties: { paymentType, expireTime }} = useBookingDetailSubmit({ onCompleted: props.onCompleted, data });
    const {
        // loading: isFeeLoading,
        // onCalculateServiceFeeForAddAdditionalService,
        serviceFeeByBookingDetailId, 
        totalFee: totalServiceFee,
        totalPaymentFee,
        onRecalculateServiceFeeByPaymentMethod,
    } = useLoadBookingDetailServiceFee(data);

    const [services, setServices] = useState<IService[]>([]);
    const servicesByUserIdMap = useMemo(() => {
        return services.reduce((acc, service) => {
            if (!acc[service.userId]) {
                acc[service.userId] = [];
            }
            acc[service.userId].push(service);
            return acc;
        }, {} as Record<string, IService[]>);
    }, [services]);
    const [serviceErrorsByUserId, setServiceErrorsByUserId] = useState<
        Partial<Record<string, Partial<Record<string, string>>>>
    >({});
    const totalUnitPrice = services.reduce((p, c) => (c.unitPrice || 0) + p, 0);
    const amount = totalUnitPrice + totalServiceFee + totalPaymentFee;
    
    const validateServicesError = async (services: IService[] = []) => {
        try {
            await servicesSchema.validate(services, {
                abortEarly: false,
            });
        } catch (e: any) {
            if (e.name === "ValidationError") {
                const { value: values, inner: inners } = e;
                const errors = inners.reduce(
                    (prev: Record<string, string | undefined>, curr: any) => {
                        const path = curr.path;
                        const firstMatchIndex = path.match(/\d+/)?.[0];
                        const relevantValue = values[firstMatchIndex] as IService;

                        if (relevantValue) {
                            const newPath = path.replace(
                                `[${firstMatchIndex}]`,
                                relevantValue._id
                            );
                            prev[newPath] = curr.errors[0];
                        }
                        return prev;
                    },
                    {}
                );

                return errors;
            }
        }
        return null;
    }
    const changeToConfirmMode = async () => {
        const errors = await validateServicesError(services);
        if (errors) {
            let userIdMapByServiceId: Record<string, string> = {};
            for (const service of services) {
                userIdMapByServiceId[service._id] = service.userId;
            }

            const errorsByUserId: typeof serviceErrorsByUserId = {};
            Object.entries(errors).forEach(([key, value]) => {
                const serviceId = key.split(".")[0];
                const userId = userIdMapByServiceId[serviceId];

                if (userId) {
                    if (!errorsByUserId[userId]) {
                        errorsByUserId[userId] = {};
                    }
                    errorsByUserId[userId][key] = value as string;
                }
            });

            setServiceErrorsByUserId(errorsByUserId);
            return;
        }
        setMode("confirm");
    };

    const handleAddService = async (user: IBookingDetailPopupUser, bookingDetail: IBookingDetail, segmentIndex: number) => {
        const { id: userId } = user;
        const firstCodename = props.itemCodenames[0];
        const { isInternational = true } = BookingHelpers.getFlightExtraInfo(bookingDetail, userId);
        const newService: IService = {
            _id: Helpers.generateRandomId(),
            bookingDetailId: bookingDetail.id,
            bookingId: bookingDetail.bookingId,
            segmentIndex,

            userId,
            description: undefined,
            itemId: firstCodename ? firstCodename.code.toString() : undefined,
            unitPrice: undefined,
            isInternational,
        }

        const currentBookingDetailServices = services.filter(s => s.bookingDetailId === bookingDetail.id && s.userId === userId && s.segmentIndex === segmentIndex);
        try {
            const errors = await validateServicesError(currentBookingDetailServices);
            const ableToAddNewService = errors === null;
            if (ableToAddNewService) {
                // const nextBookingDetailLength = currentBookingDetailServices.length + 1;
                // onCalculateServiceFeeForAddAdditionalService({
                //     userId: user.id,
                //     detailId: bookingDetail.id,
                //     quantity: nextBookingDetailLength,
                // }, paymentType, totalUnitPrice);
                setServices((services) => [...services, newService]);
            } else {
                setServiceErrorsByUserId((errorsByUserId) => ({ ...errorsByUserId, [userId]: errors }));
            }
        } catch (e: any) {
            const err = Helpers.renderExceptionError(e);
            Helpers.showAlert(err, "error");
        }
    };
    const handleRemoveService = (service: IService) => {
        const userId = service.userId;
        setServices((services) => services.filter((s) => s._id !== service._id));
        setServiceErrorsByUserId((errorsByUserId) => {
            const newErrorsByUserId = { ...errorsByUserId };

            const serviceErrorsByCurrentUser = newErrorsByUserId[userId];
            if (serviceErrorsByCurrentUser) {
                newErrorsByUserId[userId] = Object.keys(
                    newErrorsByUserId[userId]
                ).reduce((prev, key) => {
                    const containServiceId = key.includes(service._id); // key = `${serviceId}.${field}`
                    if (!containServiceId) {
                        prev[key] = serviceErrorsByCurrentUser[key];
                    }
                    return prev;
                }, {} as Partial<Record<string, string>>);
            }

            return newErrorsByUserId;
        });
    };
    const handleUpdateService = (
        newService: IService,
        keyChanged: keyof IService
    ) => {
        const userId = newService.userId;
        let pure = true;
        let newServices = [] as IService[];
        setServices((services) => {
            newServices = services;
            const index = services.findIndex(
                (service) => service._id === newService._id
            );
            if (index === -1) {
                return services;
            }

            pure = services[index][keyChanged] === newService[keyChanged];
            if (pure) {
                return services;
            }

            newServices = [...services];
            newServices[index] = newService;
            return newServices;
        });
        if (!pure && keyChanged === "unitPrice" && paymentType !== PaymentType.Debt) {
            const newTotalUnitPrice = newServices.reduce((p, c) => (c.unitPrice || 0) + p, 0);
            onRecalculateServiceFeeByPaymentMethod(paymentType, newTotalUnitPrice);
        }
        setServiceErrorsByUserId((errorsByUserId) => {
            const key = `${newService._id}.${keyChanged}`;
            const newErrorsByUserId = { ...errorsByUserId };
            const serviceErrorsByCurrentUser = { ...newErrorsByUserId[userId] };

            if (serviceErrorsByCurrentUser[key] === undefined) {
                return errorsByUserId;
            }
            if (serviceErrorsByCurrentUser) {
                delete serviceErrorsByCurrentUser[key];
                newErrorsByUserId[userId] = serviceErrorsByCurrentUser;
            }

            return newErrorsByUserId;
        });
    };
    const handleSubmit = () => {
        onAddAdditionServicesSubmit({
            services,
            serviceFeeByBookingDetailId,
            paymentFee: totalPaymentFee,
            amount,
            getItemNameByItemId: (itemId: string) => {
                return props.itemCodenames.find((c) => c.code === itemId)?.detail.name;
            },
        });
    };

    const renderPaymentContentAndRecalculateServiceFee = useCallback(({ title }: { title: string }) => {
        return renderPaymentContent({
            title,
            // onChangePaymentType: (paymentType: PaymentType) => {
            //     onRecalculateServiceFeeByPaymentMethod(paymentType, totalUnitPrice);
            // }
            allowChangePaymentType: false,
        });
    }, [renderPaymentContent])

    const renderContent = () => {
        const submitLoadingShown = isSubmitLoading;

        const editModeShown = !submitLoadingShown && mode === "edit";

        const confirmModeShown = !submitLoadingShown && mode === "confirm";
        const servicesByBookingDetailId = services.reduce((acc, service) => {
            if (!acc[service.bookingDetailId]) {
                acc[service.bookingDetailId] = [];
            }
            acc[service.bookingDetailId].push(service);
            return acc;
        }, { } as Record<string, IService[]>);
        const userConfirmData = users.reduce((acc, user) => {
            const details = getBookingDetailsByUserId(user.id).reduce((acc, detail) => {
                const servicesOfCurrentUser = servicesByBookingDetailId[detail.id]?.filter(s => s.userId === user.id) || [];
                if (servicesOfCurrentUser.length > 0) {
                    acc.push({ ...detail, services: servicesOfCurrentUser });
                }

                return acc;
            }, [] as (IBookingDetail & { services: IService[] })[]);
            if (!acc[user.id] && details.length >= 1) {
                acc[user.id] = {
                    userId: user.id,
                    fullName: user.fullName,
                    details,
                };
            }

            return acc; 
        }, { } as Record<string, { userId: string, fullName: string, details: (IBookingDetail & { services: IService[] })[] }>);

        return <Box>
            <Box sx={{
                display: { xs: submitLoadingShown ? "block" : "none" },
            }}>
                {renderLoadingContent()}
            </Box>

            <Box sx={{
                display: { xs: editModeShown ? "block" : "none" },
            }}>
                <EditModeFlightBookingDetailAdditionalServicesPopup
                    users={users}
                    errorsByUserId={serviceErrorsByUserId}
                    renderBookingDetailExtraContent={renderBookingDetailExtraContent}
                    renderSummary={renderSummary}
                    renderPaymentContent={renderPaymentContentAndRecalculateServiceFee}
                    onAddService={handleAddService}
                    serviceCodenames={props.itemCodenames}
                    servicesByUserIdMap={servicesByUserIdMap}
                    onRemoveService={handleRemoveService}
                    onUpdateService={handleUpdateService}
                />
            </Box>

            <Box sx={{
                display: { xs: confirmModeShown ? "block" : "none" },
            }}>
                <ConfirmModeFlightBookingDetailAdditionalServicesPopup
                    expireTime={expireTime}
                    currency={currency}
                    renderSummary={renderSummary}
                    data={Object.values(userConfirmData)}
                    serviceCodenames={props.itemCodenames}
                    paymentMethodName={props.paymentMethodCodenames.find(({ code }) => code === paymentType).name}
                />
            </Box>
        </Box>
    }
    const getActions = () => {
        if (isSubmitLoading) {
            return {
                hasActionButton: false,
                title: Strings.Common.PROCESSING,
            }
        }
        if (mode === "edit") {
            return {
                onAction: changeToConfirmMode,
                hasActionButton: true,
                buttonAction: Strings.BOOKING.CREATE_ADDITION_SERVICE_ORDER,
                title: Strings.BOOKING.ADDITION_SERVICE_POPUP_CREATE_TITLE,
                onClickCloseIcon: props.onClose,
            }
        }

        const numOfServices = services.length;
        if (mode === "confirm") {
            return {
                onAction: numOfServices ? handleSubmit : undefined,
                hasActionButton: true,
                buttonAction: Strings.Common.CONFIRM,
                closeButton: Strings.BOOKING.POPUP_GO_BACK_BUTTON,
                onClose: () => setMode("edit"),
                title: Strings.BOOKING.ADDITION_SERVICE_POPUP_CONFIRM_TITLE,
                onClickCloseIcon: props.onClose,
            }
        }

        return { };
    }

    const renderSummary = (args?: { showDivider?: boolean }) => {
        if (mode === "confirm") {
            return <Box display="flex" flexDirection="column">
                <TextWithPrice
                    color="warning"
                    text={Strings.BOOKING.ADDITION_SERVICE_TOTAL_PRICE_TEXT}
                    currency={currency}
                    fontWeight="bold"
                    price={totalUnitPrice}
                />
            </Box>
        }

        return (
            <Box display="flex" flexDirection="column">
                <TextWithPrice
                    text={Strings.BOOKING.ADDITION_SERVICE_TOTAL_PRICE_TEXT}
                    currency={currency}
                    fontWeight="bold"
                    price={totalUnitPrice}
                />
                <TextWithPrice text={Strings.Common.SERVICE_FEE} price={"-"}
                    fontWeight="bold"
                />
                <TextWithPrice text={Strings.Common.TAX_OF_SERVICE_FEE} price={"-"}
                    fontWeight="bold"
                />
                {(mode === "edit" ? true : totalPaymentFee > 0) && (<>
                    <TextWithPrice text={Strings.BOOKING.PAYMENT_FEE}
                        price={"-"}
                        fontWeight="bold"
                    />
                </>)}
                <TextWithPrice
                    text={Strings.BOOKING.POPUP_TOTAL}
                    fontWeight="bold"
                    currency={currency}
                    color="warning"
                    price={amount}
                />
            </Box>
        );
    }
    return (
        <>
            <Modal
                {...getActions()}
                fullWidth
                visible={props.visible}
                maxWidth={mode === "edit" ? "lg" : "md"}
            >
                {renderContent()}
            </Modal>
        </>
    );
}

export default BookingDetailAdditionalServicesPopup;
