import moment from "moment";
import { useMemo } from "react";
import { Grid, } from "@mui/material";
import { Box, Typography } from "@maysoft/common-component-react";

import Helpers from "commons/helpers";
import useDataBookingOrder from "../useDataBookingOrder.hook";

import { BookingOrderType } from "constants/enum";
import { IDetailBooking } from "components/Booking/useDataRequestBooking.hook";
import Strings from "constants/strings";



const CardBasicBookingOrder = (props: {
    index: number;
    orderCode: string;
    orderDate: string;
    paymentStatus: number;
    orderDetailType: number;
    dataBooking: IDetailBooking;
}) => {
    const {
        listOrderDetailType,
        getPaymentStatusByBookingOrder,
    } = useDataBookingOrder();

    const newOrderDate = useMemo(() => (Helpers.getDateValue(props.orderDate)), [props.orderDate]);

    const extraInfoFirst: any = useMemo(() => {
        if (Helpers.isNullOrEmpty(props?.dataBooking?.bookingDetails?.[0]?.extraInfo)) {
            return undefined;
        } else {
            return Helpers.converStringToJson(props?.dataBooking?.bookingDetails?.[0]?.extraInfo);
        }
    }, [props?.dataBooking?.bookingDetails?.[0]?.extraInfo]);

    const newValue_LastTicketDate = useMemo(() => {
        if (!Helpers.isNullOrEmpty(extraInfoFirst?.LastTicketDate)) {
            const valueConver = Helpers.convertDDMM_To_MMDD(extraInfoFirst?.LastTicketDate);

            const valueUnix = moment.utc(valueConver).unix();

            return valueUnix;
        } else {
            return undefined;
        }
    }, [extraInfoFirst?.LastTicketDate]);

    const reservationCode = useMemo(() => {
        if (extraInfoFirst && !Helpers.isNullOrEmpty(extraInfoFirst?.IssueTicketInfo)) {
            const issueTicketInfo = Helpers.converStringToJson(extraInfoFirst?.IssueTicketInfo);
            return issueTicketInfo?.Booking?.Itineraries?.[0]?.reservation_code;
        } else {
            return "";
        };
    }, [extraInfoFirst]);;


    const newDataPaymentStatus = useMemo(() => {
        const newdata = getPaymentStatusByBookingOrder({
            bookingOrderType: BookingOrderType.Main,
            paymentStatus: Number(props?.dataBooking?.paymentStatus),
            confirmStatusBooking: Number(props?.dataBooking?.confirmStatus),
        });

        return newdata;
    }, [
        getPaymentStatusByBookingOrder,
        props?.dataBooking?.paymentStatus,
        props?.dataBooking?.confirmStatus,
    ]);

    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={6} lg={7}>
                    <Box gap={1} display={"grid"}>
                        <Typography variant="button" fontWeight="bold">
                            {`${Strings.BOOKING_ORDER.DETAIL.ORDER_CODE}: `}
                            {props?.orderCode || "-"}
                        </Typography>
                        <Typography variant="button">
                            {`${Strings.BOOKING_ORDER.DETAIL.BOOKING_SERVICE}: `}
                            {listOrderDetailType?.find(el => el.code === props.orderDetailType)?.name || "-"}
                        </Typography>
                        {!Helpers.isNullOrEmpty(props.dataBooking?.externalBookingCode) &&
                            <Typography variant="button">
                                {"Booking Code: "}
                                {props.dataBooking?.externalBookingCode || ""}
                            </Typography>
                        }
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={5}>
                    <Box gap={1} display={"flex"} justifyContent="flex-end" flexDirection="column">
                        <Typography variant="button">
                            {`${Strings.BOOKING_ORDER.DETAIL.CREATE_ORDER_DATE}: `}
                            {newOrderDate ? moment(newOrderDate).format("HH:mm:ss DD/MM/YYYY") : "-"}
                        </Typography>
                        <Typography variant="button">
                            {`${Strings.BOOKING_ORDER.DETAIL.PAYMENT_STATUS}: `}
                            {
                                <Typography variant="button" color={newDataPaymentStatus?.color as any}>
                                    {newDataPaymentStatus?.title || "-"}
                                </Typography>
                            }
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </>
    )
};

export default CardBasicBookingOrder;