import moment from "moment";
import { useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";

import Helpers from "commons/helpers";
import ModuleBookingRefundOrder from "components/BookingRefundOrder";
import Screens from "constants/screens";
import useModule from "hooks/useModule";
import Constants from "../../constants";

import { DashboardLayout } from "layout";

const BookingOrderRefundScreen = () => {
    const [searchParams] = useSearchParams();

    const request = useMemo(() => {
        return {
            pageNumber: Number(searchParams.get("pageNumber") || 1),
            pageSize: Number(searchParams.get("pageSize") || Constants.ROW_PER_PAGE),

            endTime: !Helpers.isNullOrEmpty(searchParams.get("endTime")) ? searchParams.get("endTime") : moment().endOf("month").unix(),
            startTime: !Helpers.isNullOrEmpty(searchParams.get("startTime")) ? searchParams.get("startTime") : moment().startOf("month").unix(),

            code: searchParams.get("code"),
            searchText: searchParams.get("searchText"),
            customServiceType: searchParams.get("customServiceType"),
            paymentStatus: Helpers.isNullOrEmpty(searchParams.get("paymentStatus")) ? undefined : Number(searchParams.get("paymentStatus")),
        }
    }, [])

    const { moduleProps, title, route } = useModule({
        request,
        pathName: {
            list: Screens.REFUND_ORDER_LIST,
            detail: Screens.REFUND_ORDER_EDIT,
        },
        keyOfStrings: "ORDER",
    });

    return (
        <DashboardLayout isPermission {...{ title, route }}>
            <ModuleBookingRefundOrder
                {...moduleProps}
            />
        </DashboardLayout>
    );
};

export default BookingOrderRefundScreen;