import moment from "moment";
import Helpers from "commons/helpers";
import Constants from "constants/index";
import Strings from "constants/strings";
import OrderService from "services/sale/order.service";
import UserService from "services/identity/user.service";
import PartnerService from "services/sale/partner.service";
import useAdministrativeDivision from "hooks/useAdministrativeDivision";
import OrganizationService from "services/identity/organization.service";

import { ICodename, IMultiLanguageContent } from "commons/interfaces";
import { ConfirmStatus, ItineraryType, PaymentStatus, Status } from "constants/enum";
import PropertyContentService, { IPrice, IRequestGetDetail, IRoom, ITotals } from "services/booking/propertyContentService";



// #region Interface Booking
export interface IDetailBooking {
    seller: string;
    sellerName: string;
    sellerEmail: string;
    sellerPhoneNumber: string;

    buyer: string;
    buyerName: string;

    brandId: string;
    brandName: string;
    brandAddress: string;

    manufacturerId: string;
    manufacturerName: string;

    supplierId: string;
    supplierName: string;
    supplierCode: string;

    tenantCode: string;
    serviceFee: number;
    serviceCode: string;
    organizationId: string;
    organization: {
        id: string
        type: number
        ownerId: string
        tenantCode: string
        tenantName: string
        organizationProfiles: IOrganizationProfile[]
        name: IMultiLanguageContent
        description: IMultiLanguageContent
    };

    bookingCode: string;
    bookingOrderId: string;
    bookingDetails: IBookingDetail[];

    name: string;
    email: string;
    phoneNumber: string;

    endTime: string;
    startTime: string;

    totalAmount: number;
    quoteAmount: number;

    adultSlot: number;
    childrenSlot: number;
    childrenSlotDetail: any;

    location: string;
    orderId: string;
    currency: string;
    description: string;
    referenceCode: string;
    confirmStatus: number;
    bookingQuantity: number;

    extraInfo: string;
    members?: string[];
    needApprove: number;
    paymentStatus: number;
    externalBookingCode: string;
    itineraryDetailConfirmStatus: number;

    id: string;
    possessionKey?: string;
    status: number;
    createUserName: string;
    createTime: string;
    createUser: string;
    updateTime: string;
    updateUser: string;
};

export interface IOrganizationProfile {
    id: string
    email: string
    name: IMultiLanguageContent
    description: IMultiLanguageContent
    faxNumber: string
    taxNumber: string
    timeZone: string
    location: string
    currency: string
    addressId: string
    phoneNumber: string
}

export interface IBookingDetail {
    type: ItineraryType;
    itemName: any;
    itemSku: any;
    itemStatus: number;
    itemAttributes: any[];
    itemPhotos: any;
    itineraryType?: number;
    bookingId: string;
    itemId: string;
    description: any;
    unitPrice: number;
    unitCost: number;
    quantity: number;
    amount: number;
    amendType: number;
    extraInfo: string;
    id: string;
    status: number;
    createTime: string;
    createUser: string;
    updateTime: string;
    updateUser: string;
    referenceId: string;
    // Custom fields
    extraInfoParsed?: IBookingDetailFlightExtraInfo;
}

export interface IBookingDetailFlightExtraInfoContact {
    title: string,
    first_name: string,
    last_name: string,
    phone_code: string,
    phone_number: string,
    email: string,
}
export interface IBookingDetailFlightExtraInfo {
    IndexCompareData: number;
    Leg: number;
    SessionId: any;
    ConfirmedPrice: number;
    BookingCode: any;
    SearchKey: string;
    SupplierCode: string;
    Occupancy: any[];
    FlightId: string;
    CarrierOperator: string;
    CarrierOperatorName: any;
    CarrierMarketingObj: IFlightCarrierObj;
    CarrierOperatorObj: IFlightCarrierObj;
    FlightNumber: string;
    CabinClass: string;
    BookingClass: string;
    FlightDuration: number;
    DepartDate: number;
    DepartPlace: string;
    DepartPlaceName: any;
    ArrivalPlace: string;
    ArrivalPlaceName: any;
    ArrivalDate: number;
    DepartPlaceObj: IFlightPlaceObj;
    ArrivalPlaceObj: IFlightPlaceObj;
    FlightType: number;
    TotalFareAmount: number;
    TotalFareBasic: number;
    TotalTaxAmount: number;
    Currency: string;
    ArrivalDT: string;
    DepartDT: string;
    Amount: number;
    IssueTicketInfo: string;
    LastTicketDate: any;
    SegmentsList: ISegmentsList[];
    ResultBookingErr: any;
    IsInternational: boolean;
    FreeBaggage: IFreeBaggage[];
    ETickets: any;
    Contact: IBookingDetailFlightExtraInfoContact;
    // customField
    IssueTicketInfoParsed?: IBookingDetailFlightExtraInfoIssueTicketInfos;
    UserPassenger: Record<string, string>; // maysoft-user-id: deeptech-id(pax-id)
}
export interface IFreeBaggage {
    Name: string;
    Code: any;
    IsHandBaggage?: boolean;
    is_hand_baggage?: boolean;
    Quantity: number;
    PaxType: number;
}
export interface IFlightCarrierObj {
    Code: string;
    LogoUrl: string;
    Name: {
        en: string;
        vi: string;
    };
}
export interface IFlightPlaceObj {
    Id: string;
    Country: string;
    CountryCode: string;
    City: string;
    CityCode: string;
    Code: string;
    Name: string;
    Timezone: string;
}
export interface ISegmentsList {
    Index: number
    DepartPlace: string
    DepartPlaceName: any
    DepartDate: number
    ArrivalPlace: string
    ArrivalPlaceName: any
    ArrivalDate: number
    DepartPlaceObj: IFlightPlaceObj
    ArrivalPlaceObj: IFlightPlaceObj
    CarrierOperator: string
    CarrierOperatorName: any
    CarrierMarketingObj: IFlightCarrierObj;
    CarrierOperatorObj: IFlightCarrierObj;
    FlightNumber: string
    Aircraft: string
    FlightDuration: number
    DepartTerminal: string
    ArrivalTerminal: string
    DepartDT: string
    ArrivalDT: string
}
export interface IBookingDetailFlightExtraInfoIssueTicketInfos {
    is_success: boolean;
    error_code: any;
    Booking: {
        Status: string;
        Last_ticket_date: any;
        Itineraries: {
            leg: number;
            reservation_code: string;
            e_tickets: {
                pax_id: number;
                ticket_number: string;
                pax_number: any;
            }[];
            free_baggages: {
                name: string;
                is_hand_baggage: string;
                quantity: number;
                pax_type: string;
            }[];
        }[];
    };
}

export interface IRequestCreateBooking {
    serviceCode: string
    organizationId: string

    id?: string
    type: number

    buyer: string
    seller: string

    supplierId: string
    supplierCode: string

    brandId: string
    manufacturerId: string

    email: string
    lastName: string
    firstName: string
    phoneNumber: string
    countryPhonenumber: string

    endTime: number
    startTime: number
    location: string
    currency: string
    extraInfo: string
    propertyId: string
    description: string

    adultSlot: number
    childrenSlot: number
    childrenSlotDetail: string

    bookingCode: string
    bookingQuantity: number
    bookingDetailRequests: IBookingDetailRequest[]
}

export interface IBookingDetailRequest {
    id?: string
    roomId: string
    itemId: string
    orderId?: string
    quantity: number
    bedGroupId: string
    occupancy: {
        adultSlot: number
        childrenOld: number[]
    }[]
    bookingInfoRequest: {
        firstName: string
        lastName: string
    }[]
    type: number
    extraInfo: string
    bookingNo: string
    bookingClass: string
}

export interface IPersionalInfo {
    id?: string;
    name?: string;
    email?: string;
    phoneNumber?: string;
}

export interface IDataExtraInfoByBookingDetail {
    SupplierCode: string
    Currency: string
    PropertyId: string
    RoomId: string
    ItemId: string
    BedGroupId: string
    RoomName: string
    CustomerName: string
    InfoRoomGuest: any
    AdultQuantity: number
    ChildQuantity: number
    Tax: number
    Amount: number
    BedType: string
    Links: any
    Occupancy: {
        AdultSlot: number
        ChildrenOld: number[]
    }[]
    Amenities: {
        Id: string
        Name: string
    }[]
}

export interface IResponseUpdateBooking {
    id: number
    possessionKey?: string
    type: number
    serviceCode: string
    organizationId: number
    seller: number
    buyer: number
    supplierId: number
    manufacturerId: number
    brandId: number
    firstName: string
    lastName: string
    phoneNumber: string
    countryPhonenumber: string
    email: string
    startTime: number
    endTime: number
    bookingQuantity: number
    adultSlot: number
    childrenSlot: number
    childrenSlotDetail: string
    description: string
    location: string
    currency: string
    supplierCode: string
    bookingCode: string
    extraInfo: string
    propertyId: string
    bookingDetailRequests: {
        id: number
        orderId: number
        roomId: string
        bedGroupId: string
        itemId: number
        quantity: number
        occupancy: {
            adultSlot: number
            childrenOld: number[]
        }[]
        type: number
        flightType: number
        bookingClass: string
        bookingNo: string
        extraInfo: string
    }[]
}
// #endregion Interface Booking

enum EReservationType {
    Canceled = "canceled",
    Pending = "pending",
    WaitingConfirm = "waitingconfirm",
    Issued = "issued",
    WaitingRefund = "waitingrefund",
    Refunded = "refunded",
};

const userService = new UserService();

const useDataRequestBooking = () => {
    const { getCountries } = useAdministrativeDivision();

    const VALUE_NULL = "NUL";

    const listReservationType: ICodename[] = [
        {
            code: EReservationType.Canceled,
            name: Strings.STATUS.PAYMENT.Cancel,
        },
        {
            code: EReservationType.Pending,
            name: Strings.STATUS.PAYMENT.Pending,
        },
        {
            code: EReservationType.WaitingConfirm,
            name: Strings.STATUS.PAYMENT.WaitingConfirm,
        },
        {
            code: EReservationType.Issued,
            name: Strings.STATUS.PAYMENT.Issued,
        },
        {
            code: EReservationType.WaitingRefund,
            name: Strings.STATUS.PAYMENT.WaitingRefund,
        },
        {
            code: EReservationType.Refunded,
            name: Strings.STATUS.PAYMENT.Refunded,
        },
    ];

    const requestStatusList: ICodename[] = [
        {
            code: ConfirmStatus.Rejected,
            name: Strings.STATUS.CONFIRM_STATUS.Rejected,
        },
        {
            code: ConfirmStatus.Pending,
            name: Strings.STATUS.CONFIRM_STATUS.Pending,
        },
        {
            code: ConfirmStatus.Completed,
            name: Strings.STATUS.CONFIRM_STATUS.Completed,
        },
    ];

    const itineraryTypeList: ICodename[] = [
        {
            code: ItineraryType.Flight,
            name: Strings.BOOKING.FLIGHT,
        },
        {
            code: ItineraryType.Hotel,
            name: Strings.BOOKING.HOTEL,
        },
    ];

    const genReservationtype = (type: EReservationType) => {
        let out: any = {};

        switch (type) {
            case EReservationType.Canceled:
                out.hasOrderId = undefined;
                out.paymentStatus = undefined;
                out.expiryDate = moment().unix();
                out.status = [Status.Active, Status.Cancel];
                break;
            case EReservationType.Pending:
                out.hasOrderId = 0;
                out.expiryDate = undefined;
                out.status = [Status.Active];
                out.paymentStatus = undefined;
                break;
            case EReservationType.WaitingConfirm:
                out.status = [Status.Active];
                out.hasOrderId = undefined;
                out.expiryDate = undefined;
                out.paymentStatus = [PaymentStatus.New, PaymentStatus.WaitingConfirm];
                break;
            case EReservationType.Issued:
                out.status = [Status.Active];
                out.hasOrderId = undefined;
                out.expiryDate = undefined;
                out.paymentStatus = PaymentStatus.Issued;
                break;
            case EReservationType.WaitingRefund:
                out.status = [Status.Active];
                out.hasOrderId = undefined;
                out.expiryDate = undefined;
                out.paymentStatus = PaymentStatus.WaitingRefund;
                break;
            case EReservationType.Refunded:
                out.status = [Status.Active];
                out.hasOrderId = undefined;
                out.expiryDate = undefined;
                out.paymentStatus = PaymentStatus.Refunded;
                break;
            default:
                out.hasOrderId = undefined;
                out.expiryDate = undefined;
                out.paymentStatus = undefined;
                out.status = [Status.Active, Status.Cancel, Status.Reject, Status.Inactive];
                break;
        }
        return out;
    };

    // Trạng thái duyệt
    const getDataConfirmStatusByBooking = (status: ConfirmStatus) => {
        switch (status) {
            case ConfirmStatus.Rejected:
                return { icon: "block", color: "error", title: Strings.STATUS.CONFIRM_STATUS.Rejected, } as any;
            case ConfirmStatus.Processing:
                return { icon: "rotate_left_outlined", color: "warning", title: Strings.STATUS.CONFIRM_STATUS.Pending, } as any;
            case ConfirmStatus.Pending:
                return { icon: "rotate_left_outlined", color: "warning", title: Strings.STATUS.CONFIRM_STATUS.Pending, } as any;
            case ConfirmStatus.Completed:
                return { icon: "done", color: "info", title: Strings.STATUS.CONFIRM_STATUS.Completed, } as any;
            default:
                return { icon: "", color: "", title: "", } as any;
        }
    };

    // Trạng thái đặt chỗ
    const getCellReservationStatusByBooking = ({
        status,
        paymentStatus,
        lastTicketDate,
    }: {
        status?: Status,
        lastTicketDate?: number,
        paymentStatus: PaymentStatus,
    }) => {
        let newData: any = { icon: "hourglass_empty", color: "warning", title: Strings.STATUS.PAYMENT.Pending };

        if (Helpers.isNullOrEmpty(paymentStatus)) {
            newData = { icon: "hourglass_empty", color: "warning", title: Strings.STATUS.PAYMENT.Pending };
        } else {
            if (paymentStatus === PaymentStatus.New && (lastTicketDate < moment().unix())) {
                newData = { icon: "cancel", color: "secondary", title: Strings.STATUS.PAYMENT.Cancel };
            } else {
                switch (paymentStatus) {
                    case PaymentStatus.Cancel:
                        newData = { icon: "cancel", color: "secondary", title: Strings.STATUS.PAYMENT.Cancel };
                        break;

                    case PaymentStatus.Pending:
                        newData = { icon: "hourglass_empty", color: "warning", title: Strings.STATUS.PAYMENT.Pending };
                        break;
                    case PaymentStatus.New:
                        newData = { icon: "hourglass_empty", color: "warning", title: Strings.STATUS.PAYMENT.WaitingConfirm };
                        break;
                    case PaymentStatus.WaitingConfirm:
                        newData = { icon: "hourglass_empty", color: "warning", title: Strings.STATUS.PAYMENT.WaitingConfirm };
                        break;

                    case PaymentStatus.Completed:
                        newData = { icon: "check", color: "success", title: Strings.STATUS.PAYMENT.Completed };
                        break;

                    case PaymentStatus.Failed:
                        newData = { icon: "check", color: "success", title: Strings.STATUS.PAYMENT.Failed };
                        break;

                    case PaymentStatus.Issued:
                        newData = { icon: "check", color: "success", title: Strings.STATUS.PAYMENT.Issued };
                        break;

                    case PaymentStatus.WaitingPartialRefund:
                    case PaymentStatus.WaitingRefund:
                        newData = { icon: "currency_exchange", color: "secondary", title: Strings.STATUS.PAYMENT.WaitingRefund };
                        break;

                    case PaymentStatus.PartialRefunded:
                    case PaymentStatus.Refunded:
                        newData = { icon: "currency_exchange", color: "error", title: Strings.STATUS.PAYMENT.Refunded };
                        break;

                    default:
                        newData = { icon: "", color: "secondary", title: Strings.STATUS.PAYMENT.UNDEFINED };
                        break;
                }
            }
        }

        if (status === Status.Cancel) {
            newData = { icon: "cancel", color: "secondary", title: Strings.STATUS.PAYMENT.Cancel };
        };

        return newData;
    };

    const formatDate = (value?: string | Date | number, format?: string): string => {
        const result = value
            ? moment(value)
                .local()
                .format(format || "DD/MM/YYYY")
            : "";
        return result;
    };

    const formatDateName = (date: number, language: string): string => {
        let name = "";
        if (Helpers.isNullOrEmpty(date) || date === 0) {
            return name;
        } else {
            try {
                if (typeof Intl === "undefined") {
                    require("intl");
                    require("intl/locale-data/jsonp/vi");
                }
                const options: Intl.DateTimeFormatOptions = { weekday: "long" };

                name = new Intl.DateTimeFormat(language, options).format(date);
            } catch (error) {
                console.log("formatDateName", error);
            }
            return name;
        }
    };

    const formatMothName = (date: number, language: string): string => {
        let name = "";
        if (Helpers.isNullOrEmpty(date) || date === 0) {
            return name;
        } else {
            try {
                if (typeof Intl === "undefined") {
                    require("intl");
                    require("intl/locale-data/jsonp/vi");
                }
                const options: Intl.DateTimeFormatOptions = { month: "long" };

                name = new Intl.DateTimeFormat(language, options).format(date);
            } catch (error) {
                console.log("formatDateName", error);
            }
            return name;
        }
    };

    const getDateNameFormat = (time: number, language?: string) => {
        const day = `${formatDate(time, "DD")}`;
        const year = moment(time).format("YYYY");
        const dayName = `${formatDateName(time, language || "vi")}`;
        const month = `${formatMothName(time, language || "vi")}`;

        return [dayName, `${day} ${month} ${year}`].join(", ");
    };

    const getPartnerBySelectId = async (ids: string[], organizationId: string) => {
        try {
            if (!Helpers.isNullOrEmpty(organizationId)) {
                const dataMap: Map<string, IPersionalInfo> = new Map();

                const result = await new PartnerService().getPaged(Helpers.handleFormatParams({
                    selectedIds: ids,
                    pageNumber: 1,
                    pageSize: ids.length,
                    organizationId: organizationId,
                }));

                [...result?.selectedItems || []].forEach(el => {
                    if (ids.includes(el.id)) {
                        const partnerUsers = [...el.partnerUsers || []]?.find(u => u.primaryUser === 1);
                        dataMap.set(el.id, {
                            id: el.id,
                            name: partnerUsers?.name || "",
                            email: partnerUsers?.email || "",
                            phoneNumber: partnerUsers?.phoneNumber || "",
                        });
                    }
                });

                return dataMap;
            } else {
                return new Map();
            }
        } catch (error) {
            return new Map();
        }
    };

    const getDataRoomByManufacturer = async (req: IRequestGetDetail, selectIds: string[]) => {
        try {

            const listRooms: IRoom[] = [];
            const listRoomsSelected: IRoom[] = [];

            const result = await new PropertyContentService().detail(req);

            [...result.rooms || []]?.forEach(item => {
                if ([...selectIds || []].includes(item.referenceId)) {
                    listRoomsSelected.push({ ...item, selectedRate: item.rates?.[0]?.referenceId || "" });
                } else {
                    listRooms.push({ ...item, selectedRate: item.rates?.[0]?.referenceId || "" })
                }
            });

            return { listRooms, listRoomsSelected };

        } catch (error) {
            const err = Helpers.renderExceptionError(error);
            return { listRooms: [], listRoomsSelected: [] };
        }
    };

    const ORGANIZATION_ID_DEFAULT = "569629891478163456";

    const getPriceTotalByKey = (prices: IPrice | undefined, key: keyof ITotals) => {
        const result = prices?.totals?.[key]?.billingCurrency;
        return {
            value: result?.value || 0,
            currency: result?.currency,
        };
    };

    const getFirstAndLastNameByFullName = (fullName: string) => {
        const result = { firstName: "", lastName: "" };
        if (Helpers.isNullOrEmpty(result)) return result;
        const temp = fullName.split(" ");
        if (temp.length >= 2) {
            result.firstName = temp[temp.length - 1];
            result.lastName = temp.splice(0, temp.length - 1).join(" ");
        }
        if (temp.length === 1) {
            result.firstName = temp[0];
        }
        return result;
    };

    const getNumberOfAdultAndChild = (data: {
        adultSlot: number;
        childrenOld: number[];
    }[]) => {
        if (data.length === 0) {
            return { numberOfAdult: 0, numberOfChild: 0 };
        } else {
            const result = [...data || []].reduce(
                (acc, cur) => {
                    acc.numberOfAdult = acc.numberOfAdult + cur.adultSlot;
                    acc.numberOfChild = acc.numberOfChild + cur.childrenOld.length;
                    return acc;
                },
                { numberOfAdult: 0, numberOfChild: 0 }
            );
            return result;
        }
    };

    const handleConfirmData = (props: {
        lable: string;
        isWhen: boolean;
        onOk: () => void;
        onCancle: () => void;
    }) => {
        if (props.isWhen) {
            Helpers.showConfirmAlert(
                Strings.formatString("Nếu bạn thay đổi {0} này thì phòng đặt tạm thời sẽ bị mất", props.lable).toString(),
                () => {
                    props.onOk();
                },
                () => {
                    props.onCancle();
                }
            );
        } else {
            props.onOk();
        }
    };

    const getDataMapUserByIds = async (ids: string[], organizationId: string) => {
        try {
            const newDataCities: Map<string, string> = new Map();

            const newData: Map<string, {
                id: string,
                fullName: string,
                email: string;
                dateOfBirth: any;
                gender: number;
                phoneNumber: string;
                passportNo?: string;
                nationality?: string;
                passportExpiredDate?: string;
                passportIssuedPlace?: string;
            }> = new Map();

            await Promise.all([
                userService.getPaged(Helpers.handleFormatParams({
                    pageNumber: 1,
                    pageSize: ids.length,
                    listStatus: [1],
                    selectedIds: ids,
                    clientId: Constants.CLIENT_ID,
                    organizationId: organizationId,
                })),
                getCountries(),
            ]).then(async ([result, resultCities]) => {
                [...resultCities || []].forEach((item: any) => {
                    newDataCities.set(item.code, item.name);
                });

                [...result.selectedItems || []].forEach(el => {
                    let fullName = el.fullName || el.userName;

                    if (!Helpers.isNullOrEmpty(el.organizationUserProfile?.firstName)
                        || !Helpers.isNullOrEmpty(el.organizationUserProfile?.lastName)) {
                        fullName = `${el.organizationUserProfile?.lastName || ""} ${el.organizationUserProfile?.firstName || ""}`;
                    }

                    newData.set(el.id, {
                        id: el.id,
                        fullName: fullName,
                        email: el.organizationUserProfile?.email,
                        gender: el.organizationUserProfile?.gender,
                        dateOfBirth: el.organizationUserProfile?.dateOfBirth,
                        phoneNumber: el.organizationUserProfile?.phoneNumber,

                        passportNo: el.organizationUserProfile?.passportNo,
                        passportExpiredDate: el.organizationUserProfile?.passportExpiredDate,
                        nationality: newDataCities.get(el.organizationUserProfile?.nationality),
                        passportIssuedPlace: newDataCities.get(el.organizationUserProfile?.passportIssuedPlace),
                    });
                });
            }).catch(e => {
                throw e
            })

            return newData;

        } catch (error) { return new Map(); }
    };

    const getDataMapOrgaByIds = async (ids: string[]) => {
        try {

            const newIds = ids.reduce((unique, item) => unique.includes(item) ? unique : [...unique, item], []);

            const result = await new OrganizationService().getOrganizationByIds(newIds);

            const newData: Map<string, {
                id: string,
                name: string,
                code: string,
                email: string,
                phoneNumber: number,
            }> = new Map();

            [...result || []].forEach(el => {
                const itemOrgProfile = [...el.organizationProfiles || []].find(org => (org.parentGroup === "0"));

                newData.set(el.id, {
                    id: el.id,
                    code: el.organizationCode,
                    name: el.name?.value?.["vi"],
                    email: itemOrgProfile?.email,
                    phoneNumber: itemOrgProfile?.phoneNumber,
                });
            });

            return newData;

        } catch (error) { return new Map(); }
    };

    const getDataMapOrderPriceByIds = async (ids: string[]) => {
        try {

            const newIds = ids.reduce((unique, item) => unique.includes(item) ? unique : [...unique, item], []);

            const result = await new OrderService().getResponseByOrderIds(newIds);

            const newData: Map<string, {
                id: string,
                amount: number,
                orderCode: string,
                serviceFee: number,
                paymentFee: number,
                orderDate: string | number,
            }> = new Map();

            [...result || []].forEach(el => {
                newData.set(el.orderId, {
                    ...el,
                    id: el.orderId,
                });
            });

            return newData;

        } catch (error) { return new Map(); }
    };

    return {
        VALUE_NULL,
        requestStatusList,
        itineraryTypeList,
        listReservationType,
        ORGANIZATION_ID_DEFAULT,

        genReservationtype,

        formatDateName,
        formatMothName,
        getDateNameFormat,

        getDataConfirmStatusByBooking,
        getCellReservationStatusByBooking,

        handleConfirmData,
        getPriceTotalByKey,
        getPartnerBySelectId,
        getNumberOfAdultAndChild,
        getDataRoomByManufacturer,
        getFirstAndLastNameByFullName,

        getDataMapUserByIds,
        getDataMapOrgaByIds,
        getDataMapOrderPriceByIds,

    };
};

export default useDataRequestBooking;