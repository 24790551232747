import { BookingHelpers } from "commons/bookingHelpers";
import { IBookingDetailAmend, IBookingDetailPopupUser } from "components/Booking/BookingDetailContainer/interfaces";
import type { IBookingDetail, IDetailBooking } from "components/Booking/useDataRequestBooking.hook";
import { BookingDetailAmendType, ItineraryType } from "constants/enum";
import { createContext, useCallback, useContext, useMemo } from "react";
import { IRecordOrder } from "services/sale/order.service";

const AddMoreBookingServiceContext = createContext<{
    data: IDetailBooking,
    referenceMap: Record<string, IBookingDetailAmend>;
    // calculateRemainingAdditionalServices: (user: IBookingDetailPopupUser, bookingDetail: IBookingDetail, segmentIndex?: number) => number,
    isUserBookingDetailRefunded: (userId: string, bookingDetailId: string) => boolean,
    // dataWithbookingDetailsMapByUserId: IDetailBooking & { bookingDetailsByUserId: Record<string, (IBookingDetail | IBookingDetailAmend)[]> },
    getBookingDetailsByUserId: (userId: string) => (IBookingDetail | IBookingDetailAmend)[],
    isFlightBooking: boolean,
    currency: string,
    users: IBookingDetailPopupUser[],
    userMap: Record<string, IBookingDetailPopupUser>,
    order?: IRecordOrder,
}>(null);
export const useAddMoreBookingServiceContext = () => {
    return useContext(AddMoreBookingServiceContext);
}

interface AddMoreBookingServiceProviderProps {
    children: React.ReactNode;
    booking?: IDetailBooking;
    users?: IBookingDetailPopupUser[];
    order?: IRecordOrder;
}
const AddMoreBookingServiceProvider = ({ children, booking, users, order }: AddMoreBookingServiceProviderProps) => {
    const userMap = useMemo(() => {
        return users.reduce((p, user) => {
            p[user.id] = user;
            return p;
        }, {} as Record<string, IBookingDetailPopupUser>);
    }, [users])

    // Booking
    const referenceMap = useMemo(() => {
        const map = { } as Record<string, IBookingDetailAmend>;
        (booking?.bookingDetails || []).forEach(d => {
            if (d.amendType === BookingDetailAmendType.Normal) {
                map[d.id] = BookingHelpers.getBookingDetailsWithExtraInfoParsed([d])[0];
            } else {
                map[d.id] = BookingHelpers.getBookingDetailsWithAmendExtraInfoParsed([d])[0];
            }
        });

        Object.values(map).forEach(d => {
            if (d.referenceId && d.referenceId !== "0") {
                d.referenceBooking = map[d.referenceId];
            }
        });
        return map;
    }, [booking?.bookingDetails]);
    const { refundedBookingDetailMap, additionServiceBookingDetailMap } = useMemo(() => {
        const additionServiceBookingDetailMap = { } as Record<string, IBookingDetailAmend>;
        const refundedBookingDetailMap = { } as Record<string, IBookingDetailAmend>;
        const transferBookingDetailMap = { } as Record<string, IBookingDetailAmend>;
        // const amendBookingDetails = [] as IBookingDetailAmend[];

        (Object.values(referenceMap) || []).forEach(d => {
            const referenceBooking = referenceMap[d.referenceId];
            const value = { ...d, referenceBooking };

            switch (d.amendType) {
                case BookingDetailAmendType.Normal:
                    break;
                case BookingDetailAmendType.AdditionService:
                    additionServiceBookingDetailMap[d.id] = value;
                    break;
                case BookingDetailAmendType.PartialRefund:
                    refundedBookingDetailMap[d.id] = value;
                    break;
                case BookingDetailAmendType.Transfer:
                    transferBookingDetailMap[d.id] = value;
                    break;
            }
            // if (d.amendType !== BookingDetailAmendType.Normal) {
            //     amendBookingDetails.push(referenceBooking);
            // }
        });
        return {  refundedBookingDetailMap, additionServiceBookingDetailMap, transferBookingDetailMap };
    }, [referenceMap]);

    // refunded
    const userRefundedBookingDetaiMap = useMemo(() => {
        return Object.values(refundedBookingDetailMap).reduce((prev, detail) => {
            const { users } = detail.amendExtraInfoParsed;
            users.forEach(user => {
                if (!prev[user.id]) {
                    prev[user.id] = [];
                }
                prev[user.id].push(detail.referenceId);
                return prev;
            }, { } as Record<string, string[]>);
            return prev
        }, { } as Record<string, string[]>);
    }, [refundedBookingDetailMap])
    const isUserBookingDetailRefunded = useCallback((userId: string, bookingDetailId: string) => {
        return userRefundedBookingDetaiMap[userId]?.includes(bookingDetailId);
    }, [userRefundedBookingDetaiMap]);

    // Addition services
    // const addedAdditionServiceOfUserInBookingDetailMap = useMemo(() => {
    //     const userBookingDetailMap = { } as Record<string, IBookingDetailAmend[]>;
    //     // key = userId + bookingDetail.id
    //     (Object.values(additionServiceBookingDetailMap)).forEach(d => {
    //         const { users } = d.amendExtraInfoParsed;
    //         users.forEach(({ id: userId, flight }) => {
    //             const { segmentIndex } = flight || { segmentIndex: undefined };
    //             const key = `${userId}_${d.referenceId}`.concat(segmentIndex !== undefined ? `_${segmentIndex}` : "");
    //             if (!userBookingDetailMap[key]) {
    //                 userBookingDetailMap[key] = [];
    //             }

    //             userBookingDetailMap[key].push(d);
    //         });
    //     })
    //     return userBookingDetailMap;
    // }, [additionServiceBookingDetailMap])
    // const calculateRemainingAdditionalServices = useCallback((user: IBookingDetailPopupUser, bookingDetail: IBookingDetail, segmentIndex?: number) => {
    //         const MAX_ADDITIONAL_SERVICE_PER_USER_PER_SUBORDER = 5;
    //         const key = `${user.id}_${bookingDetail.id}`.concat(segmentIndex !== undefined ? `_${segmentIndex}` : "");
    //         const numberOfAdditionServiceAdded = addedAdditionServiceOfUserInBookingDetailMap[key]?.length || 0;
    //         return MAX_ADDITIONAL_SERVICE_PER_USER_PER_SUBORDER - numberOfAdditionServiceAdded;
    // }, [addedAdditionServiceOfUserInBookingDetailMap]);

    const { getLatestTransferFlightBookingDetail } = useMemo(() => {
	    const transferFlightReferenceId = { } as Record<string, IBookingDetailAmend[]>;
	    const values = Object.values(referenceMap);
	    values.forEach(v => {
            if (v.amendType === BookingDetailAmendType.Transfer && v.referenceId !== "0") {
                if (!transferFlightReferenceId[v.referenceId]) {
                    transferFlightReferenceId[v.referenceId] = [];
                }
		        transferFlightReferenceId[v.referenceId].push(v);
            }
	    })

        function getLatestTransferFlightBookingDetail({ root, userId }: { root: IBookingDetail, userId?: string }): IBookingDetail | IBookingDetailAmend {
            if (root === undefined || userId === undefined) {
                return root;
            }

	        let currentRootBooking = referenceMap[root.id];
	        while(true) {
		        const bookingId = currentRootBooking.id;
		        const nextBookings = transferFlightReferenceId[bookingId];
		        if (nextBookings === undefined || nextBookings.length === 0) {
			        break;
		        } 

                let nextBooking = undefined;
                for (let i = 0; i < nextBookings.length; i++) {
                    const booking = nextBookings[i];
                    const { success: found } = BookingHelpers.getFlightExtraInfo(booking, userId);
                    if (found) {
                        nextBooking = booking;
                        break;
                    }
                }

                if (nextBooking === undefined) {
                    break;
                }
                currentRootBooking = nextBooking;
	        }
	        return currentRootBooking;
        }

	    return { getLatestTransferFlightBookingDetail };
    }, [referenceMap]);

    const data = useMemo(() => {
        return {
            ...booking,
            bookingDetails: booking?.bookingDetails.map(d => referenceMap[d.id]),
        };
    }, [booking, referenceMap]);
    const { data: dataWithbookingDetailsMapByUserId, getBookingDetailsByUserId } = useMemo(() => {
        const map = { } as Record<string, (IBookingDetail | IBookingDetailAmend)[]>;
        const baseBookingDetails = booking?.bookingDetails.filter(d => d.amendType === BookingDetailAmendType.Normal);
        users.forEach(user => {
            const out = baseBookingDetails?.map(root => getLatestTransferFlightBookingDetail({ root, userId: user.id }));
            map[user.id] = out ?? [];
        })

        function getBookingDetailsByUserId(userId: string) {
            return map[userId];
        }
        return {
            data: {
                ...booking,
                bookingDetailsByUserId: map,
            },
            getBookingDetailsByUserId,
            // getSegmentsPerBookingDetailByUserId,
        };
    }, [booking, getLatestTransferFlightBookingDetail, users]);


    const isFlightBooking = useMemo(() => {
        return booking?.bookingDetails?.every(detail => detail.type === ItineraryType.Flight);
    }, [booking?.bookingDetails]);
    const currency = booking?.currency;
    const value = useMemo(() => ({
        users,
        rawBooking: booking,

        data,
        referenceMap,
        // dataWithbookingDetailsMapByUserId,
        getBookingDetailsByUserId,
        isFlightBooking,
        // calculateRemainingAdditionalServices,
        isUserBookingDetailRefunded,
        currency,
        userMap,
        order,
    }), [
        booking,
        currency,
        data,
        referenceMap,
        // dataWithbookingDetailsMapByUserId,
        getBookingDetailsByUserId,
        isFlightBooking,
        // calculateRemainingAdditionalServices,
        isUserBookingDetailRefunded,
        users,
        userMap,
        order,
    ]);

    return (
        <AddMoreBookingServiceContext.Provider value={value}>
            {children}
        </AddMoreBookingServiceContext.Provider>
    )
}

export default AddMoreBookingServiceProvider;