import { Box, Typography } from "@maysoft/common-component-react";
import { BookingHelpers } from "commons/bookingHelpers";
import { IBookingDetailAmendHistorySectionProps, IBookingDetailAmend } from "components/Booking/BookingDetailContainer/interfaces";
import OperationHistorySectionTitle from "../components/OperationHistorySectionTitle";
import OperationHistoryOrderDataText from "../components/OperationHistoryOrderDataText";
import OperationHistoryBookingText from "../components/OperationHistoryBookingText";
import { BookingDetailAmendType } from "constants/enum";
import Strings from "constants/strings";

const RefundAmendHistory = ({ index, details, userMap, createTime, currency, amount, createUser, paymentMethod, orderCode }: IBookingDetailAmendHistorySectionProps) => {
    const detailsViewModel = details.reduce((p, detail) => {
        const referenceId = detail.referenceId;
        if (!p[referenceId]) {
            p[referenceId] = {
                referenceBooking: detail.referenceBooking,
                amendDetails: { },
            };
        }
        detail.amendExtraInfoParsed.users.forEach(u => {
            const userId = u.id;
            if (!p[referenceId].amendDetails[userId]) {
                p[referenceId].amendDetails[userId] = [];
            }

            p[detail.referenceId].amendDetails[userId].push(detail);
        });
        return p;
    }, {} as Record<string, {
        referenceBooking: IBookingDetailAmend;
        amendDetails: Record<string, IBookingDetailAmend[]>
    }>);
    const renderTransferFlightItem = (title: string, value: string) => {
        return value && <Box display="flex" flexDirection="column" alignItems="flex-start">
            <Typography variant="caption" component="span">{title}</Typography>
            <Typography variant="button" component="span" textTransform="none" fontWeight="medium">{value}</Typography>
        </Box>
    }

    return (
        <Box display="flex" flexDirection="column" p={2} gap={1} component="div">
            <OperationHistorySectionTitle
                title={Strings.BOOKING.ADD_SERVICE_TICKET_REFUND}
                fullName={createUser?.fullName}
                updateTime={createTime}
                index={index}
            />
            <Box px={2} display="flex" flexDirection="column" gap={1}>
                <OperationHistoryOrderDataText
                    amendType={BookingDetailAmendType.PartialRefund}
                    amount={amount}
                    currency={currency} 
                    paymentMethod={paymentMethod}
                    orderCode={orderCode}
                />

                {Object.values(detailsViewModel).map(({ amendDetails: detailsByUserId, referenceBooking }) => {
                    return Object.entries(detailsByUserId).map(([userId, _]) => {
                        const { departDate, departPlaceCity, departPlace, arrivalPlaceCity, arrivalPlace, reservationCode, getTicketNumberByUserId } = BookingHelpers.getFlightExtraInfo(referenceBooking, userId);
                        const ticketNumber = getTicketNumberByUserId(userId);
                        return (
                            <Box display="flex" flexDirection="column" gap={1} key={userId}>
                                <OperationHistoryBookingText
                                    arrivalPlace={arrivalPlaceCity || arrivalPlace}
                                    departPlace={departPlaceCity || departPlace}
                                    departTime={departDate}
                                    reservationCode={reservationCode}
                                />

                                <Box display="flex" flexDirection="row">
                                    <Typography variant="button" fontWeight="bold">{userMap[userId].fullName}</Typography>  
                                    <Box ml={4} display="flex" flexDirection="row" gap={2} alignItems="center">
                                        {_.map((detail) => {
                                            return <div key={detail.id}>
                                                {renderTransferFlightItem(Strings.BOOKING.TICKET_NUMBER, ticketNumber ?? "-")}
                                            </div>
                                        })}
                                    </Box>
                                </Box>
                            </Box>
                        )
                    })
                })}
            </Box>
        </Box>
    )
}

export default RefundAmendHistory;