import {
    Autocomplete,
    Box,
    Button,
    Chip,
    DataTable,
    DataTableStatusCell,
    DatePicker,
    FormField,
    IPropListContainer,
    Typography,
    useCommonComponentContext,
} from "@maysoft/common-component-react";
import { FileDownloadOutlined, InfoOutlined } from "@mui/icons-material";
import { Card, Grid, IconButton } from "@mui/material";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";

import useDataRequestBooking from "components/Booking/useDataRequestBooking.hook";
import { Mode } from "constants/enum";
import Constants from "constants/index";
import Strings from "constants/strings";
import BookingOrderService, { IRecordBookingOrder } from "services/booking/bookingOrder.service";
import Helpers from "../../commons/helpers";
import useDataBookingOrder, { IReqDataCellStatus } from "./useDataBookingOrder.hook";

interface IReqDataGetPaged {
    code?: string;
    searchText?: string;
    paymentStatus?: number;
    customServiceType?: any;
    endTime?: string | number;
    startTime?: string | number;

    orderby?: string;
    pageSize?: number;
    pageNumber?: number;
    totalCount?: number;
}

interface IModel {
    requestData?: IReqDataGetPaged;
    requestDataTemp?: IReqDataGetPaged;
}

const bookingOrderService = new BookingOrderService();

const BookingOrderListContainer = (props: IPropListContainer<IReqDataGetPaged> & { hidenActionExport?: boolean }) => {
    const { itineraryTypeList, getDataMapOrgaByIds } = useDataRequestBooking();

    const {
        listAmendType,
        listPaymentStatus,
        listCustomServiceType,

        genPaymentStatusMain,
        genBookingTypeAmendType,
        genPaymentStatusSubOrder,
        getPaymentStatusByBookingOrder,
        getRequestStatusByBookingOrder,
    } = useDataBookingOrder();

    const { userInfo, onError, onShowLoading, onHideLoading } = useCommonComponentContext();

    const [model, setModel] = useState<IModel>({});
    const [dataRows, setDataRows] = useState<IRecordBookingOrder[]>([]);
    const [loadingDataTable, setLoadingDataTable] = useState<boolean>(false);
    const [dataMapOrg, setDataMapOrg] = useState<Map<string, any>>(new Map());

    useEffect(() => {
        getPaged({
            code: props.requestGetPaged?.code || model?.requestData?.code,
            endTime: props.requestGetPaged?.endTime || model?.requestData?.endTime,
            startTime: props.requestGetPaged?.startTime || model?.requestData?.startTime,
            searchText: props.requestGetPaged?.searchText || model?.requestData?.searchText,
            paymentStatus: props.requestGetPaged?.paymentStatus || model?.requestData?.paymentStatus,
            customServiceType: props.requestGetPaged?.customServiceType || model?.requestData?.customServiceType,

            pageSize: props.requestGetPaged?.pageSize || model?.requestData?.pageSize,
            pageNumber: props.requestGetPaged?.pageNumber || model?.requestData?.pageNumber,
            totalCount: props.requestGetPaged?.totalCount || model?.requestData?.totalCount,
        });
    }, [props.requestGetPaged]);

    const getPaged = async (req: IReqDataGetPaged) => {
        try {
            setLoadingDataTable(true);

            const pageSize = req?.pageSize || Constants.ROW_PER_PAGE;
            const pageNumber = Helpers.getPageNumber(req?.pageNumber || 1, pageSize, req?.totalCount || 0);

            const newPS = genPaymentStatusMain(req.paymentStatus);
            const newPSSubOrder = genPaymentStatusSubOrder(req.paymentStatus);
            const newEndTime = req?.endTime ?? moment().endOf("month").unix();
            const newStartTime = req?.startTime ?? moment().startOf("month").unix();

            const result = await bookingOrderService.getOrderPaged({
                pageSize,
                pageNumber,
                code: req?.code,
                searchText: req?.searchText,
                endTime: newEndTime,
                startTime: newStartTime,
                paymentStatusMain: newPS,
                paymentStatusSubOrder: newPSSubOrder,
                ...genBookingTypeAmendType(req.customServiceType),
            });

            const idOrgs = [...(result.items || [])].map((el) => el.organizationId);

            if (idOrgs.length > 0) {
                const newDataOrg = await getDataMapOrgaByIds(idOrgs);
                setDataMapOrg(newDataOrg);
            }

            setDataRows([...(result.items || [])]);

            const newModelRequestData: any = {
                ...req,
                endTime: newEndTime,
                startTime: newStartTime,
                pageSize: result.pageSize,
                pageNumber: result.currentPage,
                totalCount: result.totalCount || 0,
            };

            setModel((prev) => ({
                ...prev,
                requestData: newModelRequestData,
                requestDataTemp: newModelRequestData,
            }));

            let query: string = `?${Helpers.handleFormatParams(newModelRequestData)}`;

            props.onGetPaged({ query, totalCount: result.totalCount });
        } catch (error) {
            const err = Helpers.renderExceptionError(error);
            onError(err);
        } finally {
            // onHideLoading();
            setLoadingDataTable(false);
        }
    };

    const exportCSV = async () => {
        Helpers.showConfirmAlert(Strings.Message.SURE_TO_DOWNLOAD_FILE, async () => {
            try {
                onShowLoading();

                const newPS = genPaymentStatusMain(model?.requestData?.paymentStatus);
                const newPSSubOrder = genPaymentStatusSubOrder(model?.requestData?.paymentStatus);
                const newEndTime = model?.requestData?.endTime ?? moment().endOf("month").unix();
                const newStartTime = model?.requestData?.startTime ?? moment().startOf("month").unix();

                const result = await bookingOrderService.exportCsv({
                    code: model?.requestData?.code,
                    searchText: model?.requestData?.searchText,
                    endTime: newEndTime,
                    startTime: newStartTime,
                    paymentStatusMain: newPS,
                    paymentStatusSubOrder: newPSSubOrder,
                    pageSize: undefined,
                    pageNumber: undefined,
                    ...genBookingTypeAmendType(model.requestData?.customServiceType),
                });

                const date = moment().format("HHmmDDMMYYYY");

                var fileName = `Booking_Order_${date}.csv`;
                const downloadUrl = URL.createObjectURL(result);
                const a = document.createElement("a");

                a.href = downloadUrl;
                a.download = fileName;
                document.body.appendChild(a);
                a.click();
            } catch (error) {
                const e = Helpers.renderExceptionError(error);
                onError(e);
            } finally {
                onHideLoading();
            }
        });
    };

    // #region Filter Form
    const RenderFilterForm = () => (
        <Box>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <DatePicker
                        label={Strings.Common.TIME}
                        views={["month", "year"]}
                        placeholder={Strings.BOOKING_ORDER.GET_PAGED_FILTER.TIME_PLACEHOLDER}
                        value={Helpers.getDateValue(model?.requestDataTemp?.startTime)}
                        onChangeValue={(value: any) => {
                            const valStartTime = !Helpers.isNullOrEmpty(value) ? moment(value).startOf("month").unix() : undefined;

                            const valEndTime = !Helpers.isNullOrEmpty(value) ? moment(value).endOf("month").unix() : undefined;

                            setModel((prev) => ({
                                ...prev,
                                requestDataTemp: {
                                    ...prev.requestDataTemp,
                                    endTime: valEndTime,
                                    startTime: valStartTime,
                                },
                            }));
                        }}
                    />
                </Grid>

                <Grid item xs={12}>
                    <FormField
                        maxLength={255}
                        label={Strings.BOOKING_ORDER.GET_PAGED_FILTER.SEARCH_TEXT}
                        placeholder={Strings.BOOKING_ORDER.GET_PAGED_FILTER.SEARCH_TEXT_PLACEHOLDER}
                        defaultValue={model?.requestDataTemp?.searchText || ""}
                        onBlur={(value) => {
                            setModel((prev) => ({
                                ...prev,
                                requestDataTemp: {
                                    ...prev.requestDataTemp,
                                    searchText: value,
                                },
                            }));
                        }}
                    />
                </Grid>

                <Grid item xs={12}>
                    <FormField
                        maxLength={255}
                        label={Strings.ORDER.CODE}
                        placeholder={Strings.ORDER.ENTER_SEARCH_TEXT}
                        defaultValue={model?.requestDataTemp?.code || ""}
                        onBlur={(value) => {
                            setModel((prev) => ({
                                ...prev,
                                requestDataTemp: {
                                    ...prev.requestDataTemp,
                                    code: value,
                                },
                            }));
                        }}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Autocomplete
                        label={Strings.BOOKING_ORDER.GET_PAGED_FILTER.CUSTOM_SERVICE}
                        placeholder={Strings.BOOKING_ORDER.GET_PAGED_FILTER.CUSTOM_SERVICE_PLACEHOLDER}
                        data={listCustomServiceType || []}
                        defaultValue={model?.requestDataTemp?.customServiceType}
                        onChange={(value) => {
                            setModel((prev) => ({
                                ...prev,
                                requestDataTemp: {
                                    ...prev.requestDataTemp,
                                    customServiceType: value,
                                },
                            }));
                        }}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Autocomplete
                        label={Strings.BOOKING_ORDER.GET_PAGED_FILTER.PAYMENT_STATUS}
                        placeholder={Strings.BOOKING_ORDER.GET_PAGED_FILTER.PAYMENT_STATUS_PLACEHOLDER}
                        data={listPaymentStatus || []}
                        defaultValue={model?.requestDataTemp?.paymentStatus}
                        onChange={(value) => {
                            const newValue = !Helpers.isNullOrEmpty(value) ? Number(value) : undefined;

                            setModel((prev) => ({
                                ...prev,
                                requestDataTemp: {
                                    ...prev.requestDataTemp,
                                    paymentStatus: newValue,
                                },
                            }));
                        }}
                    />
                </Grid>
            </Grid>
        </Box>
    );

    // #region Chip Value Filter
    const RenderChipValueFilter = () => {
        return (
            <>
                {(!Helpers.isNullOrEmpty(model?.requestData?.code) ||
                    !Helpers.isNullOrEmpty(model?.requestData?.endTime) ||
                    !Helpers.isNullOrEmpty(model?.requestData?.startTime) ||
                    !Helpers.isNullOrEmpty(model?.requestData?.searchText) ||
                    !Helpers.isNullOrEmpty(model?.requestData?.paymentStatus) ||
                    !Helpers.isNullOrEmpty(model?.requestData?.customServiceType)) && (
                    <Box sx={{ marginTop: 1, marginBottom: 2 }}>
                        {!Helpers.isNullOrEmpty(model?.requestData?.startTime) && !Helpers.isNullOrEmpty(model?.requestData?.endTime) && (
                            <Chip
                                label={Strings.Common.TIME}
                                value={[
                                    moment(Helpers.getDateValue(model?.requestData?.startTime)).format("DD/MM/YYYY"),
                                    moment(Helpers.getDateValue(model?.requestData?.endTime)).format("DD/MM/YYYY"),
                                ].join(" - ")}
                                onDelete={() => {
                                    getPaged({
                                        ...model.requestData,
                                        endTime: moment().endOf("month").unix(),
                                        startTime: moment().startOf("month").unix(),
                                    });
                                }}
                            />
                        )}

                        {!Helpers.isNullOrEmpty(model?.requestData?.searchText) && (
                            <Chip
                                label={Strings.BOOKING_ORDER.COLUMNS.SEARCH_TEXT}
                                value={model?.requestData?.searchText}
                                onDelete={() => {
                                    getPaged({ ...model.requestData, searchText: undefined });
                                }}
                            />
                        )}

                        {!Helpers.isNullOrEmpty(model?.requestData?.code) && (
                            <Chip
                                label={Strings.BOOKING_ORDER.COLUMNS.ORDER_CODE}
                                value={model?.requestData?.code}
                                onDelete={() => {
                                    getPaged({ ...model.requestData, code: undefined });
                                }}
                            />
                        )}

                        {!Helpers.isNullOrEmpty(model?.requestData?.paymentStatus) && (
                            <Chip
                                label={Strings.BOOKING_ORDER.COLUMNS.PAYMENT_STATUS}
                                value={listPaymentStatus.find((el) => el.code === model?.requestData?.paymentStatus)?.name || ""}
                                onDelete={() => {
                                    getPaged({ ...model.requestData, paymentStatus: undefined });
                                }}
                            />
                        )}

                        {!Helpers.isNullOrEmpty(model?.requestData?.customServiceType) && (
                            <Chip
                                label={Strings.BOOKING_ORDER.COLUMNS.CUSTOM_SERVICE}
                                value={listCustomServiceType.find((el) => el.code === model?.requestData?.customServiceType)?.name || ""}
                                onDelete={() => {
                                    getPaged({ ...model.requestData, customServiceType: undefined });
                                }}
                            />
                        )}
                    </Box>
                )}
            </>
        );
    };

    const getReqDataCellStatus = (row: IRecordBookingOrder) => {
        return {
            bookingOrderType: row.type,
            paymentStatus: row.paymentStatus,
            confirmStatusBooking: row.confirmStatusBooking,
        } as IReqDataCellStatus;
    };

    // #region Data Columns
    const dataColumns = useMemo(
        () => [
            {
                Header: Strings.Common.ACTION,
                accessor: "orderId",
                width: "60px",
                Cell: (row: any) => (
                    <IconButton
                        color="info"
                        onClick={() => {
                            props?.onNavigate({ id: row?.row?.original?.orderId, mode: Mode.View });
                        }}
                    >
                        <InfoOutlined />
                    </IconButton>
                ),
            },
            {
                Header: Strings.Common.TIME,
                accessor: "createBooking",
                width: "120px",
                Cell: (row: any) => {
                    const newValue = Helpers.getDateValue(row.value);
                    return (
                        <Box display="grid">
                            <Typography variant="caption" fontWeight="bold" color="secondary">
                                {newValue ? moment(newValue).format("HH:mm DD/MM/YYYY") : "-"}
                            </Typography>
                        </Box>
                    );
                },
            },
            {
                Header: Strings.BOOKING_ORDER.COLUMNS.ORDER_CODE,
                accessor: "orderCode",
                width: "80px",
            },
            {
                Header: Strings.BOOKING_ORDER.COLUMNS.CUSTOM_SERVICE,
                accessor: "amendType",
                width: "140px",
                Cell: (row: any) => {
                    let name = "-";

                    const itemAmendType = listAmendType.find((el) => el.code === row.value);

                    if (itemAmendType) {
                        name = itemAmendType?.name || "-";
                    } else {
                        const itemBookingType = itineraryTypeList.find((el) => el.code === row?.row?.original?.bookingType);
                        name = itemBookingType?.name || "-";
                    }

                    return <>{name}</>;
                },
            },
            {
                Header: "Booking Code",
                accessor: "externalBookingCode",
                width: "90px",
            },
            {
                Header: Strings.BOOKING_ORDER.COLUMNS.RESERVATION_STATUS,
                accessor: "reservationCode",
                Cell: (row: any) => {
                    const newReq = getReqDataCellStatus(row?.row?.original);

                    const newdata = getRequestStatusByBookingOrder(newReq);

                    return <DataTableStatusCell data={newdata} />;
                },
            },
            {
                Header: Strings.BOOKING_ORDER.COLUMNS.ORGANIZATION_NAME,
                accessor: "organizationId",
                width: "200px",
                Cell: (row: any) => <>{dataMapOrg.get(row.value)?.name || "-"}</>,
            },
            {
                Header: Strings.BOOKING_ORDER.COLUMNS.AMOUNT,
                accessor: "amountBooking",
                width: "120px",
                Cell: (row: any) => {
                    const currency = row?.row?.original?.currency || userInfo?.userProfile?.currency;

                    const newReq = getReqDataCellStatus(row?.row?.original);

                    const valueColor = getPaymentStatusByBookingOrder(newReq)?.color as any;

                    return (
                        <Typography variant="caption" fontWeight="bold" color={valueColor}>
                            {`${valueColor === "error" ? "-" : ""} ${Helpers.formatCurrency(row?.value || 0)} ${currency}`}
                        </Typography>
                    );
                },
            },
            {
                Header: Strings.Common.STATUS,
                accessor: "paymentStatus",
                width: "150px",
                Cell: (row: any) => {
                    const newReq = getReqDataCellStatus(row?.row?.original);

                    const newdata = getPaymentStatusByBookingOrder(newReq);

                    return <DataTableStatusCell data={newdata} />;
                },
            },
        ],
        [
            dataMapOrg,
            listAmendType,
            itineraryTypeList,
            userInfo?.userProfile?.currency,
            getPaymentStatusByBookingOrder,
            getRequestStatusByBookingOrder,
        ]
    );

    return (
        <>
            <Box>
                <RenderChipValueFilter />
                <Card>
                    <Box p={2}>
                        <DataTable
                            loading={loadingDataTable}
                            rowPerPageOptions={[20, 50, 100]}
                            rowPerPage={model?.requestData?.pageSize}
                            pageNumber={model?.requestData?.pageNumber}
                            totalCount={model?.requestData?.totalCount}
                            onChangePageSize={(pageSize) => {
                                getPaged({ ...model?.requestData, pageSize });
                            }}
                            onChangePageNumber={(pageNumber) => {
                                getPaged({ ...model?.requestData, pageNumber });
                            }}
                            filterForm={<RenderFilterForm />}
                            onFilter={() => {
                                getPaged({ ...model?.requestDataTemp, pageNumber: 1 });
                            }}
                            onReset={() => {
                                getPaged({
                                    pageNumber: 1,
                                    pageSize: Constants.ROW_PER_PAGE,
                                });
                            }}
                            onCloseFilter={() => {
                                const newData = { ...model.requestData };
                                setModel({
                                    ...model,
                                    requestDataTemp: newData,
                                });
                            }}
                            table={{
                                columns: dataColumns,
                                rows: dataRows || [],
                            }}
                            leftElement={
                                <>
                                    {!props.hidenActionExport && (
                                        <Button
                                            color="success"
                                            disabled={loadingDataTable}
                                            onClick={() => {
                                                exportCSV();
                                            }}
                                        >
                                            <FileDownloadOutlined /> &nbsp; {Strings.Common.DOWNLOAD}
                                        </Button>
                                    )}
                                </>
                            }
                        />
                    </Box>
                </Card>
            </Box>
        </>
    );
};

export default BookingOrderListContainer;
