import { IBookingDetail, IBookingDetailFlightExtraInfo, IBookingDetailFlightExtraInfoIssueTicketInfos, IDetailBooking } from "components/Booking/useDataRequestBooking.hook";
import Helpers from "./helpers";
import Strings from "constants/strings";
import { BookingDetailAmendType, ConfirmStatus } from "constants/enum";
import { IBookingDetailAmend, IBookingDetailFlightAmendServicePopupExtraInfo } from "components/Booking/BookingDetailContainer/interfaces";
import moment from "moment";
import Constants from "constants/index";

const getBookingDetailsWithExtraInfoParsed = (details: IBookingDetail[]) => {
    let isAllExtraInfoParsed = true;
    const newDetails = details.map((detail) => {
        const newDetail = { ...detail };
        if (!detail.extraInfo) {
            return detail;
        }
        if (detail.extraInfoParsed) {
            return detail;
        }

        newDetail.extraInfoParsed = Helpers.converStringToJson(detail.extraInfo) as IBookingDetailFlightExtraInfo;
        isAllExtraInfoParsed = false;
        if (newDetail.extraInfoParsed) {
            if (!newDetail.extraInfoParsed.IssueTicketInfoParsed && newDetail.extraInfoParsed.IssueTicketInfo) {
                newDetail.extraInfoParsed.IssueTicketInfoParsed = Helpers.converStringToJson(newDetail.extraInfoParsed.IssueTicketInfo) as IBookingDetailFlightExtraInfoIssueTicketInfos;
            } 
            newDetail.extraInfoParsed.FreeBaggage?.forEach(baggage => {
                if (baggage.is_hand_baggage !== undefined) {
                    baggage.IsHandBaggage = baggage.is_hand_baggage;
                }
            });
        }
        return newDetail;
    })

    return isAllExtraInfoParsed ? details : newDetails;
}
const getBookingDetailsWithAmendExtraInfoParsed = (details: IBookingDetailAmend[]) => {
    return details.map((detail) => {
        const newDetail = { ...detail };
        if (!detail.extraInfo) {
            return detail;
        }
        if (detail.amendExtraInfoParsed) {
            return detail;
        }
        newDetail.amendExtraInfoParsed = Helpers.converStringToJson(detail.extraInfo) as IBookingDetailFlightAmendServicePopupExtraInfo;
        return newDetail;
    })
}

const extractFlightMetadataFromParsedExtraInfo = (
    extraInfo: IBookingDetailFlightExtraInfo,
    userId?: string,
) => {
    if (!extraInfo) {
        throw Error("No extra info provided");
    }

    const { IssueTicketInfoParsed, SegmentsList = [], CabinClass, Leg, ArrivalDT, DepartDT, CarrierMarketingObj, DepartDate, ArrivalDate, IsInternational, UserPassenger, DepartPlace, DepartPlaceObj, ArrivalPlace, ArrivalPlaceObj, FlightNumber, CarrierOperator } = extraInfo || { };
    const ticketNumber =
        IssueTicketInfoParsed?.Booking?.Itineraries?.[0]?.e_tickets?.[0]
            ?.ticket_number;
    const itineraryData = IssueTicketInfoParsed?.Booking?.Itineraries.find(i => i.leg === Leg);
    const reservationCode = itineraryData?.reservation_code;
    const baggages = itineraryData?.free_baggages || [];

    const departPlace = DepartPlace;
    const departPlaceCity = DepartPlaceObj?.City;

    const arrivalPlace = ArrivalPlace;
    const arrivalPlaceCity = ArrivalPlaceObj?.City;
    const contact = extraInfo?.Contact;

    const getTicketNumberByUserId = (userId: string) => {
        const paxId = UserPassenger[userId];
        const ticketNumber = IssueTicketInfoParsed?.Booking?.Itineraries?.[0]?.e_tickets?.find(t => t.pax_id.toString() === paxId.toString())?.ticket_number;
        return ticketNumber;
    }
    return {
        success: true,
        arrivalDT: ArrivalDT,
        arrivalPlace,
        arrivalPlaceCity,
        departDT: DepartDT,
        departPlace,
        departPlaceCity,
        segments: SegmentsList.map(segment => ({
            departPlace: segment?.DepartPlace,
            departPlaceCity: segment?.DepartPlaceObj?.City,
            arrivalPlace: segment?.ArrivalPlace,
            arrivalPlaceCity: segment?.ArrivalPlaceObj?.City,
            carrierMarketing: {
                name: segment?.CarrierMarketingObj?.Name[Strings.getLanguage() as "en" | "vi"],
                code: segment?.CarrierMarketingObj?.Code,
                logoUrl: segment?.CarrierMarketingObj?.LogoUrl,
            },
            index: segment?.Index ?? undefined,
        })),
        // ticketNumber,
        getTicketNumberByUserId,
        flightNumber: CarrierOperator.concat(FlightNumber),
        cabinClass: CabinClass,
        reservationCode,
        baggages,
        seatNumber: "-",
        carrierMarketing: {
            name: CarrierMarketingObj.Name[Strings.getLanguage() as "en" | "vi"],
            code: CarrierMarketingObj.Code,
            logoUrl: CarrierMarketingObj.LogoUrl,
        },
        departDate: DepartDate,
        arrivalDate: ArrivalDate,
        isInternational: IsInternational,
        contact,
    };
};
const extractFlightMetadataFromAmendParsedExtraInfo = (
    extraInfo: IBookingDetailFlightAmendServicePopupExtraInfo,
    userId: string,
) => {
    if (!extraInfo) {
        throw Error("No extra info provided");
    }

    const { users, contact } = extraInfo || { };
    const flight = users?.find(u => u.id === userId)?.flight;

    return {
        success: !!flight,
        arrivalDT: moment.utc(flight?.arrivalTime * 1000).format(Constants.DateFormat.DDMMYYYY_HHMM),
        departDT: moment.utc(flight?.departTime * 1000).format(Constants.DateFormat.DDMMYYYY_HHMM),

        arrivalPlace: flight?.arrivalPlaceObj?.code.toString(),
        arrivalPlaceCity: flight?.arrivalPlaceObj?.cityName?.toString(),
        departPlace: flight?.departPlaceObj?.code.toString(),
        departPlaceCity: flight?.departPlaceObj?.cityName?.toString(),
        segments: [{
            departPlace: flight?.departPlaceObj?.code.toString(),
            departPlaceCity: flight?.departPlaceObj?.cityName?.toString(),
            arrivalPlace: flight?.arrivalPlaceObj?.code.toString(),
            arrivalPlaceCity: flight?.arrivalPlaceObj?.cityName?.toString(),
            carrierMarketing: {
                name: flight?.airlineObj?.name,
                code: flight?.airlineObj?.code,
                logoUrl: "",
            },
            index: flight.segmentIndex ?? undefined,
        }],

        // ticketNumber: flight?.ticketNumber,
        getTicketNumberByUserId: (userId: string) => flight?.ticketNumber,
        flightNumber: flight?.flightNumber,
        cabinClass: flight?.cabinClass,
        reservationCode: flight?.reservationCode,
        baggages: flight?.baggage,
        seatNumber: flight?.seatNumber,

        carrierMarketing: {
            name: flight?.airlineObj?.name,
            code: flight?.airlineObj?.code,
            logoUrl: "",
        },
        departDate: flight?.departTime,
        arrivalDate: flight?.arrivalTime,
        isInternational: flight?.isInternational,
        contact,
    }
};
const getFlightExtraInfo = (flight: any, userId?: string) => {
    if ("amendExtraInfoParsed" in flight) {
        return extractFlightMetadataFromAmendParsedExtraInfo(flight.amendExtraInfoParsed, userId);
    }
    return extractFlightMetadataFromParsedExtraInfo(flight.extraInfoParsed ?? getBookingDetailsWithExtraInfoParsed([flight])[0].extraInfoParsed, userId);
}

const getBookingReservationCode = (details: IBookingDetail[], userId?: string) => {
    for (let i = 0; i < details.length; i++) {
        const { reservationCode } = getFlightExtraInfo(details[i], userId);
        return reservationCode;
    }
    return undefined;
}
const getBookingTicketNumber = (detail: IBookingDetail, userId: string) => {
    const { getTicketNumberByUserId } = getFlightExtraInfo(detail, userId);
    return getTicketNumberByUserId(userId);
}
const getBookingContactInfo = (details: IBookingDetail[]) => {
    const originalBookings = details.filter(d => d.amendType === BookingDetailAmendType.Normal);
    const firstOriginalBooking = originalBookings[0];
    let parsed = firstOriginalBooking.extraInfoParsed;
    if (!parsed) {
        parsed = Helpers.converStringToJson(firstOriginalBooking.extraInfo) as IBookingDetailFlightExtraInfo; 
    }
    const { contact } = extractFlightMetadataFromParsedExtraInfo(parsed);
    return contact;
}

const isBookingWaitingForReservationCode = (booking: IDetailBooking) => {
    const normalDetails = booking.bookingDetails.filter(detail => detail.amendType === BookingDetailAmendType.Normal);
    const detailsWithExtraInfo = getBookingDetailsWithExtraInfoParsed(normalDetails);
    return booking.confirmStatus === ConfirmStatus.Completed && getBookingReservationCode(detailsWithExtraInfo) === undefined;
}
const isBookingIssued = (booking: IDetailBooking) => {
    const normalDetails = booking.bookingDetails.filter(detail => detail.amendType === BookingDetailAmendType.Normal);
    const detailsWithExtraInfo = getBookingDetailsWithExtraInfoParsed(normalDetails);
    return booking.confirmStatus === ConfirmStatus.Completed && getBookingReservationCode(detailsWithExtraInfo) !== undefined;
}

export const BookingHelpers = {
    getBookingDetailsWithExtraInfoParsed,
    getBookingDetailsWithAmendExtraInfoParsed,
    getFlightExtraInfo,

    getBookingReservationCode,
    getBookingTicketNumber,
    getBookingContactInfo,

    isBookingIssued,
    isBookingWaitingForReservationCode,
};