import { Box, FormField, Typography } from "@maysoft/common-component-react";

const TextWithInputPrice = ({
    label,
    placeholder,
    errorMessage,
    value = 0,
    loading = false,
    currency,
    onChangeValue,
    titleFontWeight,
    showDivider,
    showMinus = false,
    required = false,
    onBlur,
}: {
    label: string;
    placeholder?: string;
    errorMessage?: string;
    value?: number;
    loading?: boolean;
    currency: string;
    titleFontWeight?: "regular" | "medium" | "bold";
    showDivider?: boolean;
    showMinus?: boolean;
    onChangeValue?: (value: number) => void;
    onBlur?: (value: number, rawValue?: string) => void;
    required?: boolean;
}) => {
    return (
        <Box
            display="grid"
            gap={3}
            gridTemplateColumns="1fr clamp(200px, 60%, 300px)"
            flexWrap="wrap"
        >
            <Typography variant="button">{label.concat(required ? "*" : "")}</Typography>
            <Box
                display="flex"
                flexDirection="row"
                alignItems="flex-start"
                gap={1}
                sx={{
                    ".MuiFormControl-root.MuiTextField-root": errorMessage ? {
                        backgroundImage: "none",
                        // fieldset border
                        ".MuiOutlinedInput-notchedOutline": {
                            borderColor: "#F44335 !important",
                        },
                        // input: currency
                        ".MuiInputAdornment-root.MuiInputAdornment-positionEnd .MuiTypography-root.MuiTypography-button": {
                            color: "#F44335",
                        },
                    } : undefined
                }}
            >
                <div style={{
                    marginTop: "4px",
                }}>
                    <Typography variant="button"
                        fontWeight="bold"
                        sx={{ opacity: showMinus? 1 : 0 }}
                    >-</Typography>
                </div>
                <FormField
                    variant="outlined"
                    fullWidth={true}
                    placeholder={placeholder}
                    isMoney
                    unit={currency}
                    required={required}
                    value={value}
                    disabled={loading}
                    error={!!errorMessage}
                    errorMessage={errorMessage}
                    onChangeValue={(value: string) =>
                        onChangeValue?.(value ? parseInt(value) : 0,)
                    }
                    onBlur={(value: string) =>
                        onBlur?.(value ? parseInt(value) : 0, value)
                    }
                />
            </Box>
        </Box>
    );
};

export default TextWithInputPrice;
