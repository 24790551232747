import { useState } from "react";
import { Card } from "@mui/material";
import { Search } from "@mui/icons-material";
import { Box, Button, FormField, Typography } from "@maysoft/common-component-react";

import Helpers from "commons/helpers";
import Strings from "constants/strings";
import RequestBookingService from "services/booking/requestBooking.service";

const bookingService = new RequestBookingService();

interface AmendBookingListScreenProps {
    onNavigate:(data?: { id: string }) => void;
}

const AmendBookingListScreen = (props: AmendBookingListScreenProps) => {
    const [searchText, setSearchText] = useState<string>("");
    const [isSearching, setIsSearching] = useState<boolean>(false);

    const handleSearch = async () => {
        if (searchText.trim().length === 0) {
            Helpers.showAlert(Strings.BOOKING.AMEND_BOOKING.ENTER_ORDER_CODE_OR_RESERVATION_CODE, "error");
        }
        try {
            setIsSearching(true);

            const bookingId = await bookingService.getIssuedByTargetCode(searchText);
            // TODO: Navigate to booking detail screen => show content by itineraryType
            props.onNavigate({ id: bookingId });
        } catch (error) {
            Helpers.showAlert(Strings.BOOKING.AMEND_BOOKING.NOT_FOUND_BOOKING_ERROR_MESSAGE, "error");
        } finally {
            setIsSearching(false);
        }
    };

    return (
            <Box px={1}>
                <Typography variant="h5">{Strings.BOOKING.TITLE_MENU_AMEND}</Typography>
                <Box my={3} display="flex" gap={2} flexDirection="column">
                    <Card>
                        <Box p={2} display="flex" flexDirection="column" gap={1}>
                            <Typography variant="button">{Strings.BOOKING.AMEND_BOOKING.SEARCH_TEXT}</Typography>
                            <Box gap={1} display="flex" flexDirection="row" flexWrap="wrap">
                                <FormField
                                    variant="outlined"
                                    placeholder={Strings.BOOKING.AMEND_BOOKING.SEARCH_TEXT_PLACEHOLDER}
                                    defaultValue={searchText}
                                    onBlur={setSearchText}
                                    disabled={isSearching}
                                    style={{
                                        maxWidth: "500px",
                                    }}
                                />
                                <Button onClick={handleSearch} loading={isSearching}><Search fontSize="small" />&nbsp;{Strings.BOOKING.AMEND_BOOKING.SEARCH_BUTTON}</Button>
                            </Box>
                        </Box>
                    </Card>

                    <Card>
                        <Box display="flex" alignItems="center" justifyContent="center" p={8}>
                            <Typography variant="button">{Strings.BOOKING.AMEND_BOOKING.AMEND_BOOKING_HERO_TEXT}</Typography>
                        </Box>
                    </Card>

                </Box>
            </Box>
    );

}

export default AmendBookingListScreen;