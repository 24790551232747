import { Box, Typography } from "@maysoft/common-component-react";
import OutlinedSelectBox from "./OutlinedSelectBox";
import { PaymentType } from "constants/enum";
import usePaymentMethodCodenames from "hooks/usePaymentMethodCodenames";
import Strings from "constants/strings";


const PaymentMethodSelector = ({
    title,
    value,
    onChange,
    renderChilrenByPaymentMethod,
    allowChangePaymentType,
}: {
    title: string;
    value?: PaymentType;
    onChange?: (newValue: any) => void;
    renderChilrenByPaymentMethod?: (value: PaymentType) => React.ReactNode;
    allowChangePaymentType?: boolean;
}) => {
    const paymentMethods = usePaymentMethodCodenames();
    return (
        <Box display="flex" gap={1} flexDirection="row" alignItems="center" justifyContent="space-between" flexWrap="wrap">
            <Typography variant="button" fontWeight="medium">
                {title}
            </Typography>


            {allowChangePaymentType ? (<Box marginLeft="auto" width="320px">
                    <OutlinedSelectBox
                        fullWidth
                        data={paymentMethods}
                        placeholder={Strings.BOOKING.AMEND_BOOKING.PAYMENT_METHOD_PLACEHOLDER}
                        initialValue={value ?? ""}
                        onChangeValue={onChange}
                    />
                    {renderChilrenByPaymentMethod?.(value)}
            </Box>)
            : (
                <Typography variant="button">
                    -
                </Typography>
            )}
        </Box>
    );
};

export default PaymentMethodSelector;