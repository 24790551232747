import { useMemo } from "react";
import { Card, Divider, Grid } from "@mui/material";
import { Box, Typography } from "@maysoft/common-component-react";

import Helpers from "commons/helpers";
import Strings from "constants/strings";


const CardPaymentInfo = ({ data }: {
    data: {
        members: string[];
        currency: string;
        serviceFee: number;
        serviceFeeTax: number;
        paymentFee: number;
        totalAmount: number;
    }
}) => {

    const numberOfMembers = new Set(data?.members).size;

    const amount = useMemo(() => (
        (data?.totalAmount || 0) - (data?.serviceFee + data?.serviceFeeTax || 0) - (data?.paymentFee || 0)
    ), [data?.totalAmount, data?.serviceFee, data?.paymentFee, data?.serviceFeeTax]);

    return (
        <Card>
            <Box p={3}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h6">{Strings.BOOKING.PAYMENT_INFO_TITLE}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{ gap: 1, display: "grid" }}>
                            <Typography variant="button" fontWeight="bold">{Strings.BOOKING.PAYMENT_INFO_FLIGHT_TICKET}</Typography>
                            <BoxTitleValue
                                title={
                                    <Typography variant="button" color="secondary">
                                        {`${Strings.BOOKING.FLIGHT_PASSENGER} x${numberOfMembers}`}
                                    </Typography>
                                }
                                value={
                                    <Typography variant="button" color="secondary">
                                        {`${Helpers.formatCurrency(amount)} ${data?.currency}`}
                                    </Typography>
                                }
                            />
                            <BoxTitleValue
                                title={
                                    <Typography variant="button" color="secondary">
                                        {`${Strings.Common.SERVICE_FEE} x${numberOfMembers}`}
                                    </Typography>
                                }
                                value={
                                    <Typography variant="button" color="secondary">
                                        {`${Helpers.formatCurrency(data?.serviceFee || 0)} ${data?.currency}`}
                                    </Typography>
                                }
                            />
                            <BoxTitleValue
                                title={
                                    <Typography variant="button" color="secondary">
                                        {Strings.Common.TAX_OF_SERVICE_FEE}
                                    </Typography>
                                }
                                value={
                                    <Typography variant="button" color="secondary">
                                        {`${Helpers.formatCurrency(data?.serviceFeeTax || 0)} ${data?.currency}`}
                                    </Typography>
                                }
                            />

                            <BoxTitleValue
                                title={
                                    <Typography variant="button" color="secondary">
                                        {Strings.BOOKING.PHI_TIEN_ICH}
                                    </Typography>
                                }
                                value={
                                    <Typography variant="button" color="secondary">
                                        {`${Helpers.formatCurrency(data?.paymentFee)} ${data?.currency}`}
                                    </Typography>
                                }
                            />
                        </Box>
                    </Grid>

                    <Grid item xs={12}> <Divider />  </Grid>

                    <Grid item xs={12}>
                        <BoxTitleValue
                            title={
                                <Typography variant="h6">{Strings.BOOKING.POPUP_TOTAL}</Typography>
                            }
                            value={
                                <Typography variant="h6">
                                    {Helpers.formatCurrency(data?.totalAmount)}
                                    {` ${data?.currency}`}
                                </Typography>
                            }
                        />
                    </Grid>
                </Grid>
            </Box>
        </Card>
    );
};

export default CardPaymentInfo;


const BoxTitleValue = (props: {
    title: JSX.Element,
    value: JSX.Element,
}) => (
    <Box sx={{
        gap: 1,
        display: "flex",
        flexWrap: "wrap",
        alignItems: "end",
        justifyContent: "space-between",
    }}>
        <Box sx={{ gap: 1, display: "grid" }}>
            {props.title}
        </Box>
        <Box sx={{ gap: 1, display: "grid" }}>
            {props.value}
        </Box>
    </Box>
)
