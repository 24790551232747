import Helpers from "commons/helpers";

import { ICodename, IMultiLang } from "commons/interfaces";
import { CashBookType, CashBookTypeV2, PaymentType, SlipStatus, SlipType } from "constants/enum";
import Strings from "constants/strings";



export interface IRecordCashBookV2 {
    id: string
    referenceCode: string
    organizationId: string
    amount: number
    currency: string
    paymentMethod: number
    transactionId: string
    slipStatus: number
    slipCode: string
    type: number
    date: string
    description: string
    cashBookType: number
}

export interface IDetailCashBookV2 {
    id: string
    referenceCode: string
    organizationId: string
    amount: number
    currency: string
    paymentMethod: number
    slipStatus: number
    slipCode: string
    type: number
    date: string
    description: string
    cashBookType: number
    transactionId: string
    invoices: IInvoiceCashBookV2[]
    depositInfo: {
        note: string
        createUser: string
        approvalUser: string
    }
}

export interface IInvoiceCashBookV2 {
    id: string
    orderId: string
    orderCode: string
    orderReferenceId: string
    orderType: number
    seller: string
    buyer: string
    invoiceCode: string
    invoiceDate: string
    discount: number
    amount: number
    tax: number
    note: string
    currency: string
    serviceFee: number
    paymentFee: number
    paymentStatus: number
    extraInformation: any
    details: IDetailInvoiceCashBookV2[]
}

export interface IDetailInvoiceCashBookV2 {
    id: string
    invoiceId: string
    type: number
    itemId: string
    description: any
    quantity: number
    discount: number
    amount: number
    tax: number
    unitPrice: number
    extraInformation: string
    productName: IMultiLang
    itemName: any
    itemDescription: any
    itemPhotos: any
    itemAttributes: any
    comboItems: any
    taxDetails: any
}


const useDataPaymentInvoice = () => {

    const cashBookTypeList: ICodename[] = [
        {
            code: CashBookTypeV2.Deposit,
            name: Strings.PAYMENT_INVOICE.Hooks.CASH_BOOK_TYPE.DEPOSIT,
        },
        {
            code: CashBookTypeV2.Pay,
            name: Strings.PAYMENT_INVOICE.Hooks.CASH_BOOK_TYPE.PAYMENT,
        },
        {
            code: CashBookTypeV2.Refund,
            name: Strings.PAYMENT_INVOICE.Hooks.CASH_BOOK_TYPE.REFUND,
        }
    ];

    const getSlipStatusCell = (slipStatus: SlipStatus) => {
        switch (slipStatus) {
            case SlipStatus.Updated:
                return { icon: "", color: "secondary", title: Strings.PAYMENT_INVOICE.Hooks.SLIP_STATUS.UPDATED };
            case SlipStatus.CreateAuto:
                return { icon: "", color: "secondary", title:Strings.PAYMENT_INVOICE.Hooks.SLIP_STATUS.CREATE_AUTO};
            case SlipStatus.Rejected:
                return { icon: "close", color: "secondary", title:Strings.PAYMENT_INVOICE.Hooks.SLIP_STATUS.REJECTED };
            case SlipStatus.Cancel:
                return { icon: "cancel", color: "secondary", title:Strings.PAYMENT_INVOICE.Hooks.SLIP_STATUS.CANCEL };
            case SlipStatus.Commit:
                return { icon: "rotate_left_outlined", color: "info", title:Strings.PAYMENT_INVOICE.Hooks.SLIP_STATUS.COMMIT };
            case SlipStatus.Pending:
                return { icon: "rotate_left_outlined", color: "warning", title:Strings.PAYMENT_INVOICE.Hooks.SLIP_STATUS.PENDING };
            case SlipStatus.Failed:
                return { icon: "warning", color: "error", title:Strings.PAYMENT_INVOICE.Hooks.SLIP_STATUS.FAILED };
            case SlipStatus.Completed:
                return { icon: "euro", color: "success", title:Strings.PAYMENT_INVOICE.Hooks.SLIP_STATUS.COMPLETED };
            default:
                return { icon: "", color: "secondary", title:Strings.PAYMENT_INVOICE.Hooks.SLIP_STATUS.UNDEFINED };
        }

    };

    const getSlipTypeCell = ({ slipType, type }: { slipType: CashBookType, type: SlipType }) => {
        if (slipType === CashBookType.Payment) {
            return { icon: "", color: "error", title: Strings.PAYMENT_INVOICE.Hooks.CASH_BOOK_TYPE.REFUND, code: CashBookTypeV2.Refund, };
        } else if ((slipType === CashBookType.Receive) && (type === SlipType.SelfCreated)) {
            return { icon: "", color: "success", title: Strings.PAYMENT_INVOICE.Hooks.CASH_BOOK_TYPE.DEPOSIT, code: CashBookTypeV2.Deposit, };
        } else {
            return { icon: "", color: "secondary", title: Strings.PAYMENT_INVOICE.Hooks.CASH_BOOK_TYPE.PAYMENT, code: CashBookTypeV2.Pay, }
        }
    }

    const getAmountCell = ({ slipType, type, amount, currency }: {
        type: SlipType,
        amount: number,
        currency?: string,
        slipType: CashBookType,
    }) => {
        const formatCurrency = `${Helpers.formatCurrency(Number(amount))} ${currency}`;

        if (slipType === CashBookType.Payment) {
            return { icon: "", color: "error", title: `-${formatCurrency}` }
        } else if (slipType === CashBookType.Receive && type === SlipType.SelfCreated) {
            return { icon: "", color: "success", title: `${formatCurrency}` }
        } else {
            return { icon: "", color: "secondary", title: `${formatCurrency}` }
        }
    };

    return {
        cashBookTypeList,
        getAmountCell,
        getSlipTypeCell,
        getSlipStatusCell,
    };
};

export default useDataPaymentInvoice;
