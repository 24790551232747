import { Box, Typography } from "@maysoft/common-component-react";
import { BookingHelpers } from "commons/bookingHelpers";
import moment from "moment";
import ContentBox from "../../components/ContentBox";
import TextWithPrice from "../../components/TextWithPrice";
import ConfirmAllChangesPopup from "../ConfirmAllChangesPopup";
import { ICodename } from "commons/interfaces";
import { IBookingDetailPopupUser, ITransferBookingDetailViewModel } from "components/Booking/BookingDetailContainer/interfaces";
import Strings from "constants/strings";
import Constants from "constants/index";

interface IConfirmModeFlightBookingDetailTransferFlightPopupProps {
    expireTime: number;
    checkedTransferRequest: ITransferBookingDetailViewModel[];
    users: IBookingDetailPopupUser[];
    cabinClassCodenames: ICodename[];
    currency: string;
    amount: number;
    serviceFee: number;
    paymentFee: number;
    totalTransferPrice: number; 
    paymentTypeName: string;
}

const ConfirmModeFlightBookingDetailTransferFlightPopup = ({ expireTime, paymentTypeName, amount, cabinClassCodenames, checkedTransferRequest, currency, serviceFee, paymentFee, totalTransferPrice, users }: IConfirmModeFlightBookingDetailTransferFlightPopupProps) => {
    // count if no details to refund
    const totalDetails = checkedTransferRequest.length;
    if (totalDetails === 0) {
        return <Box
            display={"flex"} 
            alignItems={"center"}
            justifyContent={"center"}
            paddingTop={6}
            paddingBottom={4}
        >
            <Typography variant="button">
                {Strings.BOOKING.TRANSFER_POPUP_NO_DETAILS_TO_TRANSFER}
            </Typography>
        </Box>;
    }

    return <ConfirmAllChangesPopup
            note={Strings.BOOKING.TRANSFER_POPUP_CONFIRM_NOTE}
            renderContent={() => (
                <Box display="flex" flexDirection="column" gap={1}>
                    {checkedTransferRequest.map((transferRequest, index) => {
                        const user = users.find(u => u.id === transferRequest.userId);
                        const { 
                            departPlace: oldDepartPlace,
                            arrivalPlace: oldArrivalPlace,
                            // ticketNumber: oldTicketNumber,
                            cabinClass: oldCabinClass,
                            baggages: oldBaggages,
                            // reservationCode: oldReservationCode,
                            arrivalDT: oldArrivalDT,
                            departDT: oldDepartDT,
                            carrierMarketing: oldCarrierMarketing,
                            flightNumber: oldFlightNumber,
                        } = BookingHelpers.getFlightExtraInfo(transferRequest.bookingDetail, user.id);
                        let baggageContent: React.ReactNode = null;
                        if (typeof oldBaggages === "string") {
                            baggageContent = oldBaggages;
                        } else {
                            baggageContent = oldBaggages?.length >= 1 ? oldBaggages.map(b => {
                                let typeText = b.is_hand_baggage ? Strings.BOOKING.TRANSFER_POPUP_BAGGAGE_HAND_BAGGAGE : Strings.BOOKING.TRANSFER_POPUP_BAGGAGE_CHECKED_BAGGAGE;
                                b.quantity && (typeText += ` ${b.quantity} ${Strings.Common.BOOKING_BAGGAGE_UNIT}`);
                                b.name && (typeText += ` ${b.name}`);
                                return <p>{typeText}</p>
                            }) : "-"
                        }

                        return <ContentBox
                                key={`${index}_${transferRequest._id}_${transferRequest.userId}_${transferRequest.bookingDetailId}`}
                                title={
                                    <Typography variant="button">
                                        {index + 1}. {user.fullName}
                                    </Typography>
                                }
                            >
                            <Box
                                display="grid"
                                gap={1}
                                gridTemplateColumns="repeat(3, 1fr)"
                            >
                                <span>{null}</span>
                                <Typography
                                    variant="button"
                                    fontWeight="bold"
                                    textAlign="center"
                                >
                                    {Strings.BOOKING.TRANSFER_POPUP_CURRENT_FLIGHT}
                                </Typography>
                                <Typography
                                    variant="button"
                                    fontWeight="bold"
                                    textAlign="center"
                                    color="primary"
                                >
                                    {Strings.BOOKING.TRANSFER_POPUP_NEW_FLIGHT}
                                </Typography>

                                <FlightChangeTitle>{Strings.BOOKING.TRANSFER_POPUP_CARRIER}</FlightChangeTitle>
                                <FlightChangeText>{oldCarrierMarketing?.name}</FlightChangeText>
                                <FlightChangeText>{transferRequest.airlineObj?.name}</FlightChangeText>

                                <FlightChangeTitle>{Strings.BOOKING.FLIGHT_NUMBER}</FlightChangeTitle>
                                <FlightChangeText>
                                    {oldFlightNumber || "-"}
                                </FlightChangeText>
                                <FlightChangeText>
                                    {transferRequest.flightNumber || "-"}
                                </FlightChangeText>

                                <FlightChangeTitle>
                                    {Strings.BOOKING.TRANSFER_POPUP_DEPARTURE_ARRIVAL}
                                </FlightChangeTitle>
                                <FlightChangeText>
                                    {oldDepartPlace}&nbsp;-&nbsp;{oldArrivalPlace}
                                </FlightChangeText>
                                <FlightChangeText>
                                    {transferRequest.departPlaceObj?.code}&nbsp;-&nbsp;{transferRequest.arrivalPlaceObj?.code}
                                </FlightChangeText>

                                <FlightChangeTitle>{Strings.BOOKING.TRANSFER_POPUP_TIME}</FlightChangeTitle>
                                <FlightChangeText>
                                    {moment(oldDepartDT, "DD/MM/YYYY HH:mm").format("HH:mm DD/MM/YYYY")}
                                    <br />-<br />
                                    {moment(oldArrivalDT, "DD/MM/YYYY HH:mm").format("HH:mm DD/MM/YYYY")}
                                </FlightChangeText>
                                <FlightChangeText>
                                    {moment(transferRequest.departTime * 1000).format("HH:mm DD/MM/YYYY")}
                                    <br />-<br />
                                    {moment(transferRequest.arrivalTime * 1000).format("HH:mm DD/MM/YYYY")}
                                </FlightChangeText>

                                <FlightChangeTitle>{Strings.BOOKING.CABIN_CLASS}</FlightChangeTitle>
                                <FlightChangeText>{cabinClassCodenames.find(c => c.code === oldCabinClass)?.name || "-"}</FlightChangeText>
                                <FlightChangeText>{cabinClassCodenames.find(c => c.code === transferRequest.cabinClass)?.name || "-"}</FlightChangeText>

                                {/* <FlightChangeTitle>{Strings.BOOKING.TICKET_NUMBER}</FlightChangeTitle>
                                <FlightChangeText>
                                    {oldTicketNumber || "-"}
                                </FlightChangeText>
                                <FlightChangeText>
                                    {transferRequest.ticketNumber || "-"}
                                </FlightChangeText> */}

                                <FlightChangeTitle>{Strings.BOOKING.SEAT}</FlightChangeTitle>
                                <FlightChangeText>-</FlightChangeText>
                                <FlightChangeText>{transferRequest.seatNumber || "-"}</FlightChangeText>

                                <FlightChangeTitle>{Strings.BOOKING.BAGGAGE}</FlightChangeTitle>
                                <FlightChangeText>
                                    {baggageContent}
                                </FlightChangeText>
                                <FlightChangeText>
                                    {transferRequest.baggage?.split("\n").map((item, index, array) => {
                                        const length = array.length;
                                        return <div key={index}>
                                            {index >= 1 && index < length}
                                            {item}
                                        </div>
                                    }) || "-"}
                                </FlightChangeText>

                                {/* <FlightChangeTitle>
                                    {Strings.BOOKING.RESERVATION_CODE}
                                </FlightChangeTitle>
                                <FlightChangeText>{oldReservationCode || "-"}</FlightChangeText>
                                <FlightChangeText>{transferRequest.reservationCode || "-"}</FlightChangeText> */}
                            </Box>
                        </ContentBox>
                    })}

                    <Box
                        display="flex"
                        flexDirection="column"
                        border="1px solid #d3d3d3"
                        borderRadius="4px"
                        gap={1}
                        px={2}
                        py={1}
                    >
                        <TextWithPrice
                            text={Strings.BOOKING.TRANSFER_POPUP_TOTAL_FLIGHT_CHANGE_FEE}
                            currency={currency}
                            price={totalTransferPrice}
                        />
                        <TextWithPrice
                            text={Strings.BOOKING.CONFIRM_PERIOD}
                            price={moment(expireTime * 1000).format(Constants.DateFormat.HHMM_DDMMYYYY)}
                        />
                    </Box>
                    {/* <ContentBox
                        title={
                            <Typography
                                variant="button"
                                component="span"
                                fontWeight="bold"
                            >
                                {Strings.BOOKING.TRANSFER_POPUP_FEE_TITLE}
                            </Typography>
                        }
                    >
                        <Box display="flex" flexDirection="column">
                            <TextWithPrice
                                text={Strings.Common.PAYMENT_TYPE}
                                price={paymentTypeName}
                                showDivider
                            />
                            <TextWithPrice
                                text={Strings.BOOKING.TRANSFER_POPUP_TOTAL_FLIGHT_CHANGE_FEE}
                                currency={currency}
                                price={totalTransferPrice}
                                showDivider
                            />
                            <TextWithPrice
                                text={Strings.Common.SERVICE_FEE}
                                currency={currency}
                                price={serviceFee}
                                showDivider
                            />
                            <TextWithPrice
                                text={Strings.BOOKING.PAYMENT_FEE}
                                currency={currency}
                                price={paymentFee}
                                showDivider
                            />
                            <TextWithPrice
                                text={Strings.BOOKING.POPUP_TOTAL}
                                currency={currency}
                                color="warning"
                                price={amount}
                                fontWeight="bold"
                            />
                        </Box>
                    </ContentBox> */}
                </Box>
            )}
        />
}

export default ConfirmModeFlightBookingDetailTransferFlightPopup;

const FlightChangeTitle = ({
    children,
    textAlign,
}: {
    children: React.ReactNode;
    textAlign?: "center" | "left";
}) => {
    return (
        <Typography variant="button" fontWeight="bold" textAlign={textAlign}>
            {children}
        </Typography>
    );
};
const FlightChangeText = ({ children }: { children: React.ReactNode }) => {
    return (
        <Typography variant="button" textAlign="center">
            {children}
        </Typography>
    );
};