import { useMemo } from "react";
import { Box, Typography } from "@maysoft/common-component-react";

import Helpers from "commons/helpers";
import { OrderType, PaymentStatus } from "constants/enum";
import { ICodename } from "commons/interfaces";



const CardPaymentBookingOrder = (props: {
    orderType: OrderType,
    amount: number,
    currency: string,
    serviceFee: number,
    paymentFee: number,
    paymentMethod: number,
    paymentStatus: number,
    serviceFeeTax: number,
    paymentTypeList: ICodename[]
}) => {
    const isPartialRefundType = props.orderType === OrderType.PartialRefund;
    const paymentName = useMemo(() => {
        const item = props?.paymentTypeList.find(el => el.code === props?.paymentMethod);
        if (item) {
            return item?.name;
        } else {
            return "-";
        }
    }, [props?.paymentMethod, props?.paymentTypeList])
    const itemAmountExcludeServiceFeePaymentFeeTitle = (function(){
        if (props.orderType === OrderType.PartialRefund) {
            return "Tổng tiền hoàn:";
        }
        if (props.orderType === OrderType.Book) {
            return "Tổng tiền vé:"
        }
        return "Tổng tiền:"
    })();

    return (
        <Box sx={{
            gap: 1,
            display: "grid",
            padding: "16px",
            borderRadius: "16px",
            border: "1.5px solid #F0F0F0",
        }}>
            <Typography variant="h6">
                {"Thông tin thanh toán"}
            </Typography>
            <BoxTitleValue
                title={
                    <Typography variant="button">
                        {itemAmountExcludeServiceFeePaymentFeeTitle}
                    </Typography>
                }
                value={
                    <Typography variant="button" color={"dark"}>
                        {`${Helpers.formatCurrency((props?.amount || 0) - (props?.serviceFee || 0) - (props?.paymentFee || 0))} `}
                        {props?.currency}
                    </Typography>
                }
            />

            <BoxTitleValue
                title={
                    <Typography variant="button">
                        {"Phí dịch vụ:"}
                    </Typography>
                }
                value={
                    <Typography variant="button">
                        {`${Helpers.formatCurrency((props?.serviceFee || 0) - (props?.serviceFeeTax || 0))} `}
                        {props?.currency}
                    </Typography>
                }
            />

            <BoxTitleValue
                title={
                    <Typography variant="button">
                        {"Thuế (phí dịch vụ):"}
                    </Typography>
                }
                value={
                    <Typography variant="button">
                        {`${Helpers.formatCurrency(props?.serviceFeeTax || 0)} `}
                        {props?.currency}
                    </Typography>
                }
            />

            <BoxTitleValue
                title={
                    <Typography variant="button">
                        {`Tổng cộng:`}
                    </Typography>
                }
                value={
                    <Typography variant="button" fontWeight="bold" color={"dark"}>
                        {`${Helpers.formatCurrency((props?.amount || 0) - (props?.paymentFee || 0))} `}
                        {props?.currency}
                    </Typography>
                }
            />

            <BoxTitleValue
                title={
                    <Typography variant="button">
                        {"Phí tiện ích:"}
                    </Typography>
                }
                value={
                    <Typography variant="button">
                        {`${Helpers.formatCurrency(props?.paymentFee || 0)} `}
                        {props?.currency}
                    </Typography>
                }
            />

            <BoxTitleValue
                title={
                    <Typography variant="button">
                        {"Tổng thanh toán:"}
                    </Typography>
                }
                value={
                    <Typography variant="button" fontWeight="bold" color={isPartialRefundType ? "error" : undefined}>
                        {`${Helpers.formatCurrency(props?.amount || 0)} `}
                        {props?.currency}
                    </Typography>
                }
            />

            <BoxTitleValue
                title={
                    <Typography variant="button">
                        {`Phương thức thanh toán:`}
                    </Typography>
                }
                value={
                    <Typography variant="button" fontWeight="bold">
                        {paymentName}
                    </Typography>
                }
            />
        </Box>
    )
};

export default CardPaymentBookingOrder;

const BoxTitleValue = (props: {
    title: JSX.Element,
    value: JSX.Element,
}) => (
    <Box sx={{
        gap: 1,
        display: "flex",
        flexWrap: "wrap",
        alignItems: "end",
        justifyContent: "space-between",
    }}>
        <Box sx={{ gap: 1, display: "grid" }}>
            {props.title}
        </Box>
        <Box sx={{ gap: 1, display: "grid" }}>
            {props.value}
        </Box>
    </Box>
);