import { useState } from "react";
import { CircularProgress } from "@mui/material";
import { Box, FormField, Modal, Typography } from "@maysoft/common-component-react";

import Helpers from "commons/helpers";
import OrderService from "services/sale/order.service";
import { ErrorOutline } from "@mui/icons-material";
import Strings from "constants/strings";



const orderService = new OrderService();

const ConfirmRefundPopup = ({
    visible,
    message,
    onClose,
    onCompleted,
    bookingOrderId,
}: {
    message?: string;
    visible: boolean,
    onCompleted: () => void,
    onClose: () => void, bookingOrderId: string,
}) => {

    const [reason, setReason] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [reasonError, setReasonError] = useState<string | undefined>(undefined);

    const handleRefund = async () => {
        try {
            if (!reason?.trim()) {
                setReasonError(Strings.BOOKING.CONFIRM_REFUND_MISSION_REASON_ERROR);
                return;
            };

            setIsLoading(true);

            const result = await orderService.refund({
                reason,
                id: bookingOrderId,
            });

            if (typeof result ==="object") {
                if (result?.resourceNotFound && result.value) {
                    Helpers.showAlert(result.value, "error");
                    return;
                }
            }

            Helpers.showAlert(Strings.BOOKING.CONFIRM_REFUND_SUCCESS, "success");
            onCompleted();
        } catch (e) {
            const err = Helpers.renderExceptionError(e);
            Helpers.showAlert(err, "error");
        } finally {
            setIsLoading(false);
        }
    }

    const getActions = () => {
        if (isLoading) {
            return {
                title: Strings.Common.PROCESSING,
            }
        }

        return {
            onClickCloseIcon: onClose,
            closeButton: Strings.BOOKING.CONFIRM_REFUND_LATER_BUTTON,
            buttonAction: Strings.Common.CONFIRM,
            hasActionButton: true,
            onAction: handleRefund,
            title: Strings.BOOKING.CONFIRM_REFUND_TITLE,
        }
    }
    return <Modal
        visible={visible}
        {...getActions()}
        maxWidth="xs"
        fullWidth
    >
        {isLoading
            ? (
                <Box display="flex" p={5} justifyContent="center" alignItems="center">
                    <CircularProgress size={30} />
                </Box>
            )
            : (
                <Box display="flex" flexDirection="column" gap={3} alignItems="center">
                    <ErrorOutline sx={{ fontSize: 64 }} color="warning" />
                    {message ? (
                        <Typography variant="body2" textAlign="center">
                            {message}
                        </Typography>
                    ) : (
                        <Typography variant="body2" textAlign="center">
                            {Strings.BOOKING.CONFIRM_REFUND_PROMPT}
                        </Typography>
                    )}

                    <Box sx={{
                        width: "100%",
                        "& fieldset.MuiOutlinedInput-notchedOutline": {
                            borderColor: reasonError ? "error.main" : undefined,
                        },
                    }}>
                        <FormField
                            fullWidth
                            multiline
                            variant="outlined"
                            defaultValue={reason}
                            error={!!reasonError}
                            errorMessage={reasonError}
                            placeholder={Strings.BOOKING.CONFIRM_REFUND_REASON_PLACEHOLDER}
                            onBlur={(value) => {
                                setReason(value);
                                setReasonError(undefined)
                            }}
                        />
                    </Box>
                </Box>
            )
        }
    </Modal>
}

export default ConfirmRefundPopup;