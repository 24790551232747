import { CheckBox, Box } from "@maysoft/common-component-react";
import { IBookingDetailServiceFeeItem } from "services/sale/serviceFeeSetting";
import DeeptechAccordion from "../../components/DeeptechAccordion";
import TextWithPrice from "../../components/TextWithPrice";
import { IBookingDetailPopupUser } from "../../../interfaces";
import FlightRefundDetail from "../../components/FlightRefundDetail";
import TextWithInputPrice from "../../components/TextWithInputPrice";
import Strings from "constants/strings";
import { useAddMoreBookingServiceContext } from "providers/addBookingServiceProvider";
import { IAdditionServiceCalculateServiceFeeItem } from "../../hooks/useLoadServiceFee";

const EditModeFlightBookingDetailRefundPopupContent = ({
    users,
    items,
    errors,
    onChangeItemValue: onChangeValue,

    totalPartialRefundUnitPrice,
    partialRefundAmount,
    totalServiceFee,

    isUserBookingRefunded,
    isLoadingServiceFee = false,
    renderPaymentContent,
    renderBookingDetailExtraContent,
    currency,
}: {
    users: IBookingDetailPopupUser[];
    isUserBookingRefunded: (userId: string, bookingDetailId: string) => boolean;

    items?: IAdditionServiceCalculateServiceFeeItem[];
    errors: Record<string, string>;
    onChangeItemValue: (value: IAdditionServiceCalculateServiceFeeItem) => void;

    totalPartialRefundUnitPrice: number;
    partialRefundAmount: number;
    totalServiceFee: number;

    serviceFeeByBookingDetailId: Record<string, IBookingDetailServiceFeeItem>;
    isLoadingServiceFee?: boolean;
    renderPaymentContent: ({ title }: { title: string }) => React.ReactNode;
    renderBookingDetailExtraContent: (args: { title?: string, allowNote?: boolean }) => React.ReactNode;

    currency: string;
}) => {
    const { getBookingDetailsByUserId, isFlightBooking } = useAddMoreBookingServiceContext();
    if (isFlightBooking) {
        return (
            <Box display="flex" flexDirection="column" gap={2}>
                {users.map((user) => (
                    <DeeptechAccordion
                        key={user.id}
                        data={user}
                        renderContent={() => {
                            return (
                                <Box display="flex" flexDirection="column">
                                    {getBookingDetailsByUserId(user.id).map(
                                        (bookingDetailItem) => {
                                            const isRefunded = isUserBookingRefunded(user.id, bookingDetailItem.id);
                                            const item = items.find(
                                                (c) =>
                                                    c.userId === user.id &&
                                                    c.detailId ===
                                                        bookingDetailItem.id
                                            );
                                            const isChecked = item?.checked;
                                            const unitPriceError = item ? errors?.[`${item._id}.unitPrice`] : undefined;
                                            const feeError = item ? errors?.[`${item._id}.fee`] : undefined;

                                            return (
                                                <Box
                                                    key={bookingDetailItem.id}
                                                    display="flex"
                                                    flexDirection="column"
                                                    gap={2}
                                                >
                                                    <Box
                                                        display="flex"
                                                        flexDirection="row"
                                                        flexWrap="wrap"
                                                        alignItems="center"
                                                    >
                                                        <CheckBox
                                                            value={""}
                                                            checked={isChecked}
                                                            disabled={isRefunded || isLoadingServiceFee}
                                                            onChange={( _, checked) => isRefunded
                                                                ? undefined
                                                                : onChangeValue?.({ ...item, checked: checked, userId: user.id, detailId: bookingDetailItem.id, quantity: 1, unitPrice: 0, fee: 0 })}
                                                        />
                                                        &nbsp;
                                                        <FlightRefundDetail detail={bookingDetailItem} isRefunded={isRefunded} userId={user.id} />
                                                    </Box>
                                                    {item && item.checked && (
                                                        <Box
                                                            display="flex"
                                                            flexDirection="column"
                                                            gap={1}
                                                        >
                                                            <TextWithInputPrice
                                                                label={Strings.BOOKING.REFUND_POPUP_REFUND_AMOUNT}
                                                                required
                                                                showMinus
                                                                currency={currency}
                                                                onBlur={(value, rawValue) => onChangeValue({ ...item, unitPrice: (rawValue === "" ? undefined : value), userId: user.id, detailId: bookingDetailItem.id })}
                                                                value={item.unitPrice} 
                                                                errorMessage={unitPriceError}
                                                            />
                                                            <TextWithInputPrice
                                                                required
                                                                label={Strings.BOOKING.REFUND_POPUP_REFUND_FEE}
                                                                currency={currency}
                                                                onBlur={(value, rawValue) => onChangeValue({ ...item, fee: (rawValue === "" ? undefined : value), userId: user.id, detailId: bookingDetailItem.id })}
                                                                value={item.fee} 
                                                                errorMessage={feeError}
                                                            />
                                                        </Box>
                                                    )} 
                                                </Box>
                                            );
                                        }
                                    )}
                                </Box>
                            );
                        }}
                    />
                ))}
                {renderBookingDetailExtraContent({ allowNote: true })}
                {renderPaymentContent({ title: Strings.Common.PAYMENT_TYPE })}
                <Box display="flex" flexDirection="column">
                    <TextWithPrice
                        text={Strings.BOOKING.REFUND_POPUP_TOTAL_TICKET_REFUND_PRICE}
                        currency={currency}
                        price={getRefundDisplayPrice(totalPartialRefundUnitPrice)}
                        loading={isLoadingServiceFee}
                    />
                    <TextWithPrice
                        text={Strings.Common.SERVICE_FEE_TAX_INCLUDE}
                        currency={currency}
                        price={totalServiceFee}
                        loading={isLoadingServiceFee}
                    />
                    <TextWithPrice
                        fontWeight="medium"
                        text={Strings.BOOKING.REFUND_POPUP_CUSTOMER_REFUND}
                        currency={currency}
                        color="warning"
                        price={getRefundDisplayPrice(partialRefundAmount)}
                        loading={isLoadingServiceFee}
                    />
                </Box>
            </Box>
        );
    }

    return null;
};


export default EditModeFlightBookingDetailRefundPopupContent;
const getRefundDisplayPrice = (refundAmount: number) => {
    return -refundAmount;
}