import Helpers from "commons/helpers";
import Strings from "constants/strings";
import UserService from "services/identity/user.service";

import { ICodename } from "commons/interfaces";
import { BookingDetailAmendType, BookingOrderType, ConfirmStatus, ItineraryType, OrderDetailType, OrderStatus, PaymentStatus, PaymentType, Status } from "constants/enum";


export interface IReqDataCellStatus {
    paymentStatus: number;
    bookingOrderType: number;
    confirmStatusBooking: number;
};

enum ECustomServiceType {
    ItineraryHotel = "hotel",
    ItineraryFlight = "flight",

    AmendPartialRefund = "partial-refund",
    AmendTransferFlight = "transfer-flight",
    AmendAdditionService = "addition-service",
}

const useDataBookingOrder = () => {

    const listOrderDetailType: ICodename[] = [
        { code: OrderDetailType.Hotel, name: "Khách sạn", },
        { code: OrderDetailType.Flight, name: "Chuyến bay", },
    ];

    const listOrderStatus: ICodename[] = [
        { code: OrderStatus.Cancel, name: "Đã hủy", },
        { code: OrderStatus.Pending, name: "Chờ xử lý", },
        { code: OrderStatus.New, name: "Mới", },
        { code: OrderStatus.Confirm, name: "Đã xác nhận", },
        { code: OrderStatus.Editing, name: "Đã chỉnh sửa", },
        { code: OrderStatus.PaymentConfirm, name: "Đã thanh toán", },
        { code: OrderStatus.DebtConfirm, name: "Xác nhận nợ", },
        { code: OrderStatus.ExchangeRequest, name: "Yêu cầu đổi trả", },
        { code: OrderStatus.ExchangeCancel, name: "Hủy bỏ đổi trả", },
        { code: OrderStatus.Completed, name: "Hoàn thành", },
    ];

    const listPaymentStatus: ICodename[] = [
        {
            code: PaymentStatus.Cancel,
            name: Strings.STATUS.PAYMENT.Cancel,
        },
        {
            code: PaymentStatus.New,
            name: Strings.STATUS.PAYMENT.Pending,
        },
        {
            code: PaymentStatus.Completed,
            name: Strings.STATUS.PAYMENT.Completed,
        },
        {
            code: PaymentStatus.WaitingRefund,
            name: Strings.STATUS.PAYMENT.WaitingRefund,
        },
        {
            code: PaymentStatus.Refunded,
            name: Strings.STATUS.PAYMENT.Refunded,
        },
    ];

    const paymentTypeList: ICodename[] = [
        {
            code: PaymentType.Cash,
            name: "Tiền mặt",
        },
        {
            code: PaymentType.Banking,
            name: "Chuyển khoảng",
        },
        {
            code: PaymentType.Paypal,
            name: "Paypal",
        },
        {
            code: PaymentType.VnPay,
            name: "VnPay",
        },
        {
            code: PaymentType.Mobile,
            name: "Mobile",
        },
        {
            code: PaymentType.Debt,
            name: "Quỹ xuất vé",
        },
        {
            code: PaymentType.NinePayCollection,
            name: "Chuyển khoản ngân hàng/ Quét QR code",
        },
        {
            code: PaymentType.NinePayATM,
            name: "Thẻ ATM nội địa",
        },
        {
            code: PaymentType.NinePayCredit,
            name: "Thẻ Visa/Master/JCB phát hành trong nước",
        },
        {
            code: PaymentType.NinePayCreditInternational,
            name: "Thẻ Visa/Master/JCB phát hành nước ngoài",
        },
    ];

    const listAmendType: ICodename[] = [
        {
            code: BookingDetailAmendType.AdditionService,
            name: "Dịch vụ bổ sung",
        },
        {
            code: BookingDetailAmendType.PartialRefund,
            name: "Hoàn vé",
        },
        {
            code: BookingDetailAmendType.Transfer,
            name: "Thay đổi chuyến bay",
        },
    ];

    const listCustomServiceType: ICodename[] = [
        {
            code: ECustomServiceType.ItineraryFlight,
            name: "Chuyến bay",
        },
        {
            code: ECustomServiceType.ItineraryHotel,
            name: "Khách sạn"
        },
        {
            code: ECustomServiceType.AmendAdditionService,
            name: "Dịch vụ bổ sung",
        },
        {
            code: ECustomServiceType.AmendTransferFlight,
            name: "Thay đổi chuyến bay",
        },
        {
            code: ECustomServiceType.AmendPartialRefund,
            name: "Hoàn vé",
        }
    ];

    const genBookingTypeAmendType = (type: ECustomServiceType) => {
        let out = { bookingType: undefined, amendType: undefined } as { bookingType: ItineraryType, amendType: BookingDetailAmendType };

        switch (type) {
            case ECustomServiceType.ItineraryFlight:
                out.bookingType = ItineraryType.Flight;
                out.amendType = BookingDetailAmendType.Normal;
                break;
            case ECustomServiceType.ItineraryHotel:
                out.bookingType = ItineraryType.Hotel;
                out.amendType = BookingDetailAmendType.Normal;
                break;

            case ECustomServiceType.AmendAdditionService:
                out.bookingType = undefined;
                out.amendType = BookingDetailAmendType.AdditionService;
                break;
            case ECustomServiceType.AmendTransferFlight:
                out.bookingType = undefined;
                out.amendType = BookingDetailAmendType.Transfer;
                break;
            case ECustomServiceType.AmendPartialRefund:
                out.bookingType = undefined;
                out.amendType = BookingDetailAmendType.PartialRefund;
                break;
        }
        return out;
    };

    const genPaymentStatusMain = (value: PaymentStatus) => {
        if (value === PaymentStatus.New) {
            return [PaymentStatus.New, PaymentStatus.Pending]
        } else if (value === PaymentStatus.Completed) {
            return [PaymentStatus.Issued]
        } else {
            return [value]
        }
    };

    const genPaymentStatusSubOrder = (value: PaymentStatus) => {
        if (value === PaymentStatus.Completed) {
            return [PaymentStatus.Completed]
        }
        return undefined;
    };

    // Trạng thái thanh toán
    const getPaymentStatusByBookingOrder = (props: IReqDataCellStatus) => {
        const newData = getDataCellStatus(props, true);
        return newData;
    };

    // Trạng thái đặt chỗ
    const getRequestStatusByBookingOrder = (props: IReqDataCellStatus) => {
        const newData = getDataCellStatus(props);
        return newData;
    };

    const getDataCellStatus = (props: IReqDataCellStatus, convertIssueToComplete?: boolean) => {
        let newDataCellStatus: any = { icon: "hourglass_empty", color: "warning", title: Strings.STATUS.PAYMENT.Pending };

        if (props.bookingOrderType === BookingOrderType.Main) {
            switch (props.paymentStatus) {
                case PaymentStatus.WaitingConfirm:
                    newDataCellStatus = { icon: "hourglass_empty", color: "warning", title: Strings.STATUS.PAYMENT.WaitingConfirm };
                    break;
                case PaymentStatus.Pending:
                    newDataCellStatus = { icon: "hourglass_empty", color: "warning", title: Strings.STATUS.PAYMENT.Pending };
                    break;
                case PaymentStatus.Completed: {
                    if (props.confirmStatusBooking === ConfirmStatus.Completed) {
                        const newTitle = convertIssueToComplete ? Strings.STATUS.PAYMENT.Completed : Strings.STATUS.PAYMENT.Issued;
                        newDataCellStatus = { icon: "check", color: "success", title: newTitle };
                    } else {
                        newDataCellStatus = { icon: "check", color: "success", title: Strings.STATUS.PAYMENT.Completed };
                    }
                    break;
                }
                case PaymentStatus.Failed:
                    newDataCellStatus = { icon: "check", color: "success", title: Strings.STATUS.PAYMENT.Failed };
                    break;
                case PaymentStatus.Issued:
                    const newTitle = convertIssueToComplete ? Strings.STATUS.PAYMENT.Completed : Strings.STATUS.PAYMENT.Issued;
                    newDataCellStatus = { icon: "check", color: "success", title: newTitle };
                    break;
                case PaymentStatus.PartialRefunded:
                case PaymentStatus.Refunded:
                    newDataCellStatus = { icon: "currency_exchange", color: "error", title: Strings.STATUS.PAYMENT.Refunded };
                    break;
                case PaymentStatus.WaitingPartialRefund:
                case PaymentStatus.WaitingRefund:
                    newDataCellStatus = { icon: "currency_exchange", color: "secondary", title: Strings.STATUS.PAYMENT.WaitingRefund };
                    break;
                case PaymentStatus.Cancel:
                    newDataCellStatus = { icon: "cancel", color: "secondary", title: Strings.STATUS.PAYMENT.Cancel };
                    break;
                case PaymentStatus.New:
                    newDataCellStatus = { icon: "hourglass_empty", color: "warning", title: Strings.STATUS.PAYMENT.WaitingConfirm };
                    break;
                default:
                    newDataCellStatus = { icon: "", color: "secondary", title: "Không xác định" };
                    break;
            }
        };
        if (props.bookingOrderType === BookingOrderType.SubOrder) {
            newDataCellStatus = { icon: "check", color: "success", title: Strings.STATUS.PAYMENT.Completed };
        }

        return newDataCellStatus;
    };

    const getDataMapUserByIds = async ({ ids, orgId, newDataUser }: {
        ids: string[], orgId: string, newDataUser: Map<string, string>,
    }) => {
        try {

            const newIds = ids.reduce((unique, item) => unique.includes(item) ? unique : [...unique, item], []);

            const result = await new UserService().getPaged(Helpers.handleFormatParams({
                pageNumber: 1,
                pageSize: newIds.length,
                selectedIds: newIds,
                organizationId: orgId,
            }));

            [...result.selectedItems || []].forEach(el => {
                let fullName = el.fullName || el.userName;

                if (!Helpers.isNullOrEmpty(el.organizationUserProfile?.firstName)
                    || !Helpers.isNullOrEmpty(el.organizationUserProfile?.lastName)) {
                    fullName = `${el.organizationUserProfile?.lastName || ""} ${el.organizationUserProfile?.firstName || ""}`;
                };

                newDataUser.set(el.id, fullName);
            });

            return newDataUser;

        } catch (error) { return newDataUser; }
    };

    return {
        listAmendType,
        paymentTypeList,
        listOrderStatus,
        listPaymentStatus,
        listOrderDetailType,
        listCustomServiceType,

        getDataMapUserByIds,
        genPaymentStatusMain,
        genBookingTypeAmendType,
        genPaymentStatusSubOrder,
        getPaymentStatusByBookingOrder,
        getRequestStatusByBookingOrder,
    };
};

export default useDataBookingOrder;