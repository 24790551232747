import { Box, Typography } from "@maysoft/common-component-react";
import Helpers from "commons/helpers";
import { IBookingDetail } from "components/Booking/useDataRequestBooking.hook";
import { IBookingDetailPopupUser } from "../../../interfaces";
import ConfirmAllChangesPopup from "../ConfirmAllChangesPopup";
import FlightRefundDetail from "../../components/FlightRefundDetail";
import Strings from "constants/strings";
import moment from "moment";
import Constants from "constants/index";

const ConfirmModeFlightBookingDetailRefundPopupContent = ({
    expireTime,
    refundAmount,
    checkedGroupByUserId,
}: {
    expireTime: number,
    refundAmount: number;
    checkedGroupByUserId: Record<
        string,
        {
            details: IBookingDetail[];
        } & Partial<IBookingDetailPopupUser>
    >;
}) => {
    // count if no details to refund
    const totalDetails = Object.values(checkedGroupByUserId).reduce(
        (prev, c) => prev + c.details.length,
        0
    );
    if (totalDetails === 0) {
        return <Box
            display={"flex"} 
            alignItems={"center"}
            justifyContent={"center"}
            paddingTop={6}
            paddingBottom={4}
        >
            <Typography variant="button">
                {Strings.BOOKING.REFUND_POPUP_NO_DETAILS_TO_REFUND}
            </Typography>
        </Box>;
    }

    return (
        <ConfirmAllChangesPopup
            note={Strings.BOOKING.REFUND_POPUP_CONFIRM_NOTE}
            renderContent={() => (
                <Box display="flex" flexDirection="column" gap={1}>
                    <Typography variant="button">
                        {Strings.BOOKING.REFUND_POPUP_CONFIRM_SUMMARY_TITLE}
                    </Typography>
                    {Object.values(checkedGroupByUserId).map(
                        ({ details, ...user }, index) => {
                            return (
                                <Box
                                    key={user.id}
                                    display="flex"
                                    gap={1}
                                    flexDirection="column"
                                >
                                    <Typography variant="button">
                                        {index + 1}. {Strings.BOOKING.FLIGHT_PASSENGER} {user.fullName}:
                                    </Typography>
                                    {details.map((detail) => (
                                        <Box
                                            key={`${detail.id}.${user.id}`}
                                            ml={2}
                                        >
                                            <FlightRefundDetail
                                                userId={user.id}
                                                detail={detail}
                                            />
                                        </Box>

                                    ))}
                                </Box>
                            );
                        }
                    )}

                    <Typography
                        mt={2}
                        variant="button"
                        fontWeight="medium"
                        color="primary"
                    >
                        {Strings.BOOKING.REFUND_POPUP_CUSTOMER_REFUND_TEXT}&nbsp;
                        {Helpers.formatCurrency(refundAmount)} đ
                    </Typography>
                    <Typography
                        mt={2}
                        variant="button"
                        color="secondary"
                    >
                        {Strings.BOOKING.CONFIRM_PERIOD}:&nbsp;
                        {moment(expireTime * 1000).format(Constants.DateFormat.HHMM_DDMMYYYY)}
                    </Typography>
                </Box>
            )}
        />
    );
};

export default ConfirmModeFlightBookingDetailRefundPopupContent;