import Strings from "constants/strings";

enum CarrierCode {
    Vietjet = "VJ",
    Vietnamairline = "VN",
    Bamboo = "QH",
    Viettravel = "VU",
}
const useAdditionalServiceBaggageItemCodenames = () => {
    const language = Strings.getLanguage();

    // TODO: Need to update locales
    const getItemCodenamesByCarrierCode = (carrierCode: string) => {
        switch (carrierCode) {
            case CarrierCode.Vietjet:
                return [{
                code: "Gói 15kg",
                name: "Gói 15kg",
            },
            {
                code: "Gói 20kg",
                name: "Gói 20kg",
            },
            {
                code: "Gói 25kg",
                name: "Gói 25kg",
            },
            {
                code: "Gói 30kg",
                name: "Gói 30kg",
            },
            {
                code: "Gói 35kg",
                name: "Gói 35kg",
            },
            {
                code: "Gói 40kg",
                name: "Gói 40kg",
            },
            {
                code: "Kiện HLQK 20kg",
                name: "Kiện HLQK 20kg",
            },
            {
                code: "Kiện HLQK 30kg",
                name: "Kiện HLQK 30kg",
            },
            {
                code: "Gói 50kg",
                name: "Gói 50kg",
            },
            {
                code: "Gói 60kg",
                name: "Gói 60kg",
            },
            {
                code: "Gói 70kg",
                name: "Gói 70kg",
            },
            {
                code: "Gói 80kg",
                name: "Gói 80kg",
            },
            {
                code: "Gói 90kg",
                name: "Gói 90kg",
            },
            {
                code: "Gói 100kg",
                name: "Gói 100kg",
                }];
            case CarrierCode.Vietnamairline:
                return [{
                    code: "Thêm 1 kiện",
                    name: "Thêm 1 kiện",
                }];
            case CarrierCode.Bamboo:
                return [{
                    code: "5kg",
                    name: "5kg",
                },
                {
                    code: "10kg",
                    name: "10kg",
                },
                {
                    code: "20kg",
                    name: "20kg",
                },
                {
                    code: "30kg",
                    name: "30kg",
                },
                {
                    code: "40kg",
                    name: "40kg",
                },
                {
                    code: "50kg",
                    name: "50kg",
                },
                {
                    code: "60kg",
                    name: "60kg",
                },
                {
                    code: "10kg",
                    name: "10kg",
                },
                {
                    code: "20kg",
                    name: "20kg",
                },
                {
                    code: "30kg",
                    name: "30kg",
                },
                {
                    code: "40kg",
                    name: "40kg",
                },
                {
                    code: "50kg",
                    name: "50kg",
                }];
            case CarrierCode.Viettravel:
                return [{
                    code: "15kg/p",
                    name: "15kg/p",
                },
                {
                    code: "23kg/p",
                    name: "23kg/p",
                },
                {
                    code: "32kg/p",
                    name: "32kg/p",
                },
                {
                    code: "23kg- 32kg",
                    name: "23kg- 32kg",
                },
                {
                    code: "15kg - 32kg",
                    name: "15kg - 32kg",
                },
                {
                    code: "15kg - 23kg",
                    name: "15kg - 23kg",
                },
                {
                    code: "HLQK",
                    name: "HLQK",
                },
                {
                    code: "HL Golf",
                    name: "HL Golf",
                },
                {
                    code: "Gói tiết kiệm (Hệ kiện)",
                    name: "Gói tiết kiệm (Hệ kiện)",
                },
                {
                    code: "Gói thông thái (Hệ kiện)",
                    name: "Gói thông thái (Hệ kiện)",
                },
                {
                    code: "Gói tối ưu (Hệ kiện)",
                    name: "Gói tối ưu (Hệ kiện)",
                }];
            default:
                return [];
        }
    }
    return { getItemCodenamesByCarrierCode, };
}

export default useAdditionalServiceBaggageItemCodenames;