import moment from "moment";
import { useMemo, useState } from "react";
import { Box, Typography } from "@maysoft/common-component-react";

import Helpers from "commons/helpers";
import Strings from "constants/strings";
import useDataBookingOrder from "../useDataBookingOrder.hook";

import { IOrderDetailExtends } from ".";
import { OrderType, PaymentStatus } from "constants/enum";
import { IOrderDetail } from "services/sale/order.service";



const CardItemBookingOrder = (props: {
    currency: string;
    orderType: number;
    paymentStatus: number;
    organizationId: string;
    orderDetails: IOrderDetail[];
    dataMapExtraInfoBooking: Map<string, string>;
    dataMapOrderDetail: Map<string, IOrderDetailExtends>;
}) => {
    const renderItemRow = (item: IOrderDetail, index: number) => {
        let totalAmount = (item?.amount || 0);

        if (props?.orderType !== OrderType.AdditionService) {
            totalAmount = totalAmount + (item?.fee || 0);
        };

        const itemOrderDetailParent = props.dataMapOrderDetail.get(item.referenceId);

        return (
            <Box key={item.id} sx={{
                gap: 1,
                display: "grid",
                alignItems: "end",
                gridTemplateColumns: "1fr 50px 150px",
            }}>
                <RenderFlightInfo
                    index={index}
                    orderType={props?.orderType}
                    organizationId={props.organizationId}
                    extraInformation={item?.extraInformation}

                    orderTypeParent={itemOrderDetailParent?.orderType}
                    extraInformationParent={itemOrderDetailParent?.extraInformation}

                    extraInfoBooking={props.dataMapExtraInfoBooking.get(item?.externalId)}
                    extraInfoBookingParent={props.dataMapExtraInfoBooking.get(itemOrderDetailParent?.externalId)}
                />
                <Typography variant="button">
                    {`x ${Helpers.formatCurrency(item?.quantity || 0)}`}
                </Typography>
                <Box textAlign={"end"}>
                    <Typography variant="button" color={"dark"}>
                        {Helpers.formatCurrency(totalAmount)} {item?.currency || props.currency}
                    </Typography>
                </Box>
            </Box>
        );
    };

    return (
        <Box sx={{
            gap: 1,
            display: "grid",
            padding: "16px",
            borderRadius: "16px",
            border: "1.5px solid #F0F0F0",
        }}>
            <Box gap={0.5} display={"grid"}>
                <Typography variant="h6">
                    {"Thông tin đơn hàng"}
                </Typography>
            </Box>
            <Box gap={2} marginTop={1} display={"grid"}>
                {[...props?.orderDetails || []].map((item, index) => (renderItemRow(item, index)))}
            </Box>
        </Box>
    );
};

export default CardItemBookingOrder;

const getTextDateTimeFlight = (valueTime: any) => {
    const day = Strings.Common.DAY.toLocaleLowerCase();
    const time = moment(Helpers.getDateValue(valueTime)).format("HH:mm");
    const date = moment(Helpers.getDateValue(valueTime)).format("DD/MM/YYYY");

    return `${time} ${day} ${date}`;
};

const getDataFlightInfo = (extraInfo: any) => {
    const flight = extraInfo?.users?.[0]?.flight;

    if (Helpers.isNullOrEmpty(flight)) {
        return {
            ticketNumber: "",
            reservationCode: "",
            departTime: extraInfo?.DepartDate,
            arrivalTime: extraInfo?.ArrivalDate,
            cabinClass: extraInfo?.CabinClass || "",
            departPlaceCode: extraInfo?.DepartPlaceObj?.Code || "",
            arrivalPlaceCode: extraInfo?.ArrivalPlaceObj?.Code || "",
            carrierName: extraInfo?.CarrierMarketingObj?.Name?.["vi"] || "",
        };
    } else {
        return {
            departTime: flight?.departTime,
            arrivalTime: flight?.arrivalTime,
            ticketNumber: flight?.ticketNumber,
            cabinClass: flight?.cabinClass || "",
            carrierName: flight?.airlineObj?.name || "",
            reservationCode: flight?.reservationCode || "",
            departPlaceCode: flight?.departPlaceObj?.code || "",
            arrivalPlaceCode: flight?.arrivalPlaceObj?.code || "",
        };
    };
};

const RenderFlightInfo = ({
    index,
    orderType,
    organizationId,
    orderTypeParent,
    extraInformation,
    extraInfoBooking,
    extraInformationParent,
    extraInfoBookingParent,
}: {
    index: number,
    orderType: number,
    organizationId: string,
    orderTypeParent: number,
    extraInformation: string,
    extraInfoBooking: string,
    extraInformationParent?: string,
    extraInfoBookingParent?: string,
}) => {
    const { getDataMapUserByIds } = useDataBookingOrder();

    const [dataMapUser, setDataMapUser] = useState<Map<string, string>>(new Map());

    const dataExtraInfo = useMemo(() => (Helpers.converStringToJson(extraInformation)), [extraInformation]);
    const dataExtraInfoParent = useMemo(() => (Helpers.converStringToJson(extraInformationParent)), [extraInformationParent]);

    const dataFlightInfo = useMemo(() => (getDataFlightInfo(dataExtraInfo)), [dataExtraInfo]);
    const dataFlightParentInfo = useMemo(() => (getDataFlightInfo(dataExtraInfoParent)), [dataExtraInfoParent]);

    useMemo(() => {
        if (orderType === OrderType.PartialRefund) {
            (async () => {
                let newDataUser = new Map();

                newDataUser = await getDataMapUserByIds({
                    orgId: organizationId,
                    newDataUser: newDataUser,
                    ids: [...dataExtraInfo?.users || []].map(el => el.id),
                });

                setDataMapUser(newDataUser);
            })()
        };
    }, [orderType, dataExtraInfo?.users]);

    const BoxDefault = () => (
        <Box gap={0.5} display={"grid"}>
            <Typography variant="button" sx={{ marginBottom: 0.5 }}>
                {"Mã đặt chỗ: "}
                {dataFlightInfo?.reservationCode}
            </Typography>

            <Typography variant="button">
                {`Chuyến bay ${dataFlightInfo?.departPlaceCode} - ${dataFlightInfo?.arrivalPlaceCode}`}
            </Typography>

            <Typography variant="button">
                -&nbsp;{`Hãng ${dataFlightInfo?.carrierName}`}
                &nbsp;-&nbsp;{`Hạng ${dataFlightInfo?.cabinClass}`}
            </Typography>

            <Typography variant="button">
                -&nbsp;{"Khởi hành - Hạ cánh:"}&ensp;
                {getTextDateTimeFlight(dataFlightInfo?.departTime)}
                &ensp;-&ensp;
                {getTextDateTimeFlight(dataFlightInfo?.arrivalTime)}
            </Typography>
        </Box>
    );

    const BoxAdditionService = () => (
        <Box gap={0.5} display={"grid"}>
            {(index === 0) &&
                <Box marginBottom={0.5} display={"grid"}>
                    <Typography variant="button">
                        {"Dịch vụ bổ sung"}
                    </Typography>
                    <Typography variant="button">
                        {"Mã đặt chỗ: "}
                        {dataFlightInfo?.reservationCode}
                    </Typography>
                </Box>
            }
            <Typography variant="button">
                {`Chuyến bay ${dataFlightParentInfo?.departPlaceCode} - ${dataFlightParentInfo?.arrivalPlaceCode}`}
            </Typography>
            <Typography variant="button">
                &ensp;&#8226;&ensp;{dataExtraInfo?.itemName?.["vi"]}
            </Typography>
        </Box>
    );

    const BoxPartialRefund = () => (
        <Box gap={0.5} display={"grid"}>
            {(index === 0) &&
                <Box marginBottom={0.5} display={"grid"}>
                    <Typography variant="button">
                        {"Hoàn vé"}
                    </Typography>
                    <Typography variant="button">
                        {"Mã đặt chỗ: "}
                        {dataFlightInfo?.reservationCode}
                    </Typography>
                </Box>
            }
            <Typography variant="button">
                {`${index + 1}. ${dataMapUser.get(dataExtraInfo?.users?.[0]?.id)}`}
            </Typography>
            <Typography variant="button">
                {`Chuyến bay ${dataFlightParentInfo?.departPlaceCode} - ${dataFlightParentInfo?.arrivalPlaceCode}`}
                &ensp;&#8226;&ensp;
                <Typography variant="button" fontWeight="bold">
                    {"Số vé: "}
                    <Typography variant="button">{dataFlightInfo?.ticketNumber}</Typography>
                </Typography>
            </Typography>
        </Box>
    );

    const BoxTransfer = () => (
        <Box gap={0.5} display={"grid"}>
            {(index === 0) &&
                <Box marginBottom={0.5} display={"grid"}>
                    <Typography variant="button">
                        {"Thay đổi chuyến bay"}
                    </Typography>
                    <Typography variant="button">
                        {"Mã đặt chỗ: "}
                        {dataFlightInfo?.reservationCode}
                    </Typography>
                </Box>
            }

            <Typography variant="button">
                {"Chuyến bay mới: "}
                {`Chuyến bay ${dataFlightInfo?.departPlaceCode} - ${dataFlightInfo?.arrivalPlaceCode}`}
            </Typography>

            <Typography variant="button">
                -&nbsp;{`Hãng ${dataFlightInfo?.carrierName}`}
                &nbsp;-&nbsp;{`Hạng ${dataFlightInfo?.cabinClass}`}
            </Typography>

            <Typography variant="button">
                -&nbsp;{"Khởi hành - Hạ cánh:"}&ensp;
                {getTextDateTimeFlight(dataFlightInfo?.departTime)}
                &ensp;-&ensp;
                {getTextDateTimeFlight(dataFlightInfo?.arrivalTime)}
            </Typography>
        </Box>
    );

    switch (orderType) {
        case OrderType.AdditionService:
            return <BoxAdditionService />;
        case OrderType.PartialRefund:
            return <BoxPartialRefund />;
        case OrderType.Transfer:
            return <BoxTransfer />;
        default:
            return <BoxDefault />;
    };
};