import { FlightTakeoff } from "@mui/icons-material";
import { useEffect, useMemo, useState } from "react";
import { CircularProgress, Grid } from "@mui/material";
import { Box, Typography, useCommonComponentContext } from "@maysoft/common-component-react";

import Helpers from "commons/helpers";
import CardTimeLineFlight from "./cardTimeLineFlight";
import useDataRequestBooking from "../../useDataRequestBooking.hook";

import { IBookingDetail } from "../../useDataRequestBooking.hook";
import Strings from "constants/strings";


interface IRecordETickets {
    pax_id: number,
    pax_name: string,
    ticket_number: number,
};

interface IRecordFreeBaggage {
    name: string,
    quantity: number,
    pax_type: string,
    is_hand_baggage: boolean,
};

interface IRecordPassengers {
    id: number
    dob: string
    type: string
    gender: string
    last_name: string
    first_name: string
    nationality: string
    passport_number: string
    passport_exp_date: string
    passport_issuing_country: string
};

const getValueBaggage = (data?: IRecordFreeBaggage) => {
    if (!Helpers.isNullOrEmpty(data?.quantity)
        || !Helpers.isNullOrEmpty(data?.name)) {
        return `${data?.quantity || ""} kiện ${data?.name || ""}`;
    } else {
        return "-"
    }
};

const CardItemFlightInfo = (props: {
    data: IBookingDetail,
    reservationCode?: string;
    isShowReservationCode?: boolean,
}) => {
    const {
        language,
    } = useCommonComponentContext();

    const {
        VALUE_NULL,
        getDateNameFormat,
    } = useDataRequestBooking();

    const [reservationCode, setReservationCode] = useState("");
    const [passengers, setPassengers] = useState<IRecordPassengers[]>([]);
    const [dataMapFreeBaggage, setDataMapFreeBaggage] = useState<IRecordFreeBaggage[]>([]);
    const [dataMapETickets, setDataMapETickets] = useState<Map<string | number, IRecordETickets>>(new Map());

    const extraInfo = useMemo(() => Helpers.converStringToJson(props.data?.extraInfo), [props.data]);

    const valueNumber = useMemo(() => props.isShowReservationCode ? 3 : 4, [props.isShowReservationCode]);

    useEffect(() => {

        const leg = extraInfo?.Leg;

        const issueTicketInfo = Helpers.converStringToJson(extraInfo?.IssueTicketInfo);

        const newItemItinerary = [...issueTicketInfo?.Booking?.Itineraries || []].find(el => el.leg === leg);

        let newMapE: Map<string, IRecordETickets> = new Map();
        for (const e of [...newItemItinerary?.e_tickets || []]) {
            newMapE.set(e.pax_id, e);
        };

        setDataMapETickets(newMapE);
        setPassengers([...extraInfo?.Passengers || []]);

        let newDataFB: IRecordFreeBaggage[] = [];
        // [...newItemItinerary?.free_baggages || []]
        if (newDataFB.length > 0) {
            setDataMapFreeBaggage(newDataFB);
        } else {
            for (const el of [...extraInfo?.FreeBaggage || []]) {
                newDataFB.push({
                    pax_type: el.pax_type,
                    name: el.Name || el.name,
                    quantity: el.Quantity || el.quantity,
                    is_hand_baggage: el.is_hand_baggage,
                })
            }
            setDataMapFreeBaggage(newDataFB);
        }

        if (Helpers.isNullOrEmpty(newItemItinerary?.reservation_code)) {
            setReservationCode(props.reservationCode);
        } else {
            setReservationCode(newItemItinerary?.reservation_code);
        }

    }, [extraInfo, props.reservationCode]);

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} >
                <Box sx={{
                    borderRadius: "4px",
                    padding: "12px 16px",
                    backgroundColor: "#EBF0FF",
                }}>
                    <Box sx={{
                        gap: 2,
                        display: "flex",
                        flexWrap: "wrap",
                        alignItems: "end",
                    }}>
                        <Typography variant="h5">
                            {`${extraInfo?.DepartPlaceObj?.City} ${extraInfo?.DepartPlaceObj?.Country}`}
                        </Typography>
                        <FlightTakeoff />
                        <Typography variant="h5">
                            {`${extraInfo?.ArrivalPlaceObj?.City} ${extraInfo?.ArrivalPlaceObj?.Country}`}
                        </Typography>
                    </Box>

                    <Typography variant="button" fontWeight="bold">
                        {`${getDateNameFormat(extraInfo?.DepartDate * 1000)}`}
                    </Typography>

                    {props.isShowReservationCode &&
                        <Box>
                            <Box sx={{
                                gap: 1,
                                px: 1.5,
                                py: 0.75,
                                borderRadius: 1,
                                alignItems: "center",
                                display: "inline-flex",
                                justifyContent: "center",
                                backgroundColor: "#FFFFFF",
                            }}>
                                <Typography variant="button" sx={{ gap: 1, display: "flex", alignItems: "center" }}>
                                    {`${Strings.BOOKING.RESERVATION_CODE}: `}
                                    {!Helpers.isNullOrEmpty(reservationCode) &&
                                        <Typography variant="button" fontWeight="bold">
                                            {(reservationCode === VALUE_NULL) ? "" : reservationCode}
                                        </Typography>
                                    }
                                    {Helpers.isNullOrEmpty(reservationCode) &&
                                        <Box sx={{ display: "flex", height: "1rem" }}>
                                            <CircularProgress size={"1rem"} />
                                        </Box>
                                    }
                                </Typography>
                            </Box>
                        </Box>
                    }
                </Box>
            </Grid>

            <Grid item xs={12} >
                <Box sx={{
                    gap: 1,
                    display: "flex",
                    flexWrap: "wrap",
                    alignItems: "end",
                }}>
                    <Box
                        component={"img"}
                        src={extraInfo?.CarrierMarketingObj?.LogoUrl}
                        sx={{
                            width: "21px",
                            height: "21px",
                            borderRadius: "50%",
                            objectFit: "contain",
                            border: "1px #c3c3c3 solid"
                        }}
                    ></Box>
                    <Typography variant="button">{(extraInfo?.CarrierMarketingObj?.Name as any)?.[language]}</Typography>
                    <Typography variant="button">{" - "}</Typography>
                    <Typography variant="button">
                        {[extraInfo?.CarrierMarketingObj?.Code, extraInfo?.FlightNumber].join("")}
                    </Typography>
                </Box>
            </Grid>

            <Grid item xs={12}>
                <CardTimeLineFlight
                    data={extraInfo}
                    language={language}
                />
            </Grid>

            <Grid item xs={12}>
                {
                    passengers.map((item, index: number) => (
                        <Box marginBottom={3} key={item.id}>
                            <Grid container spacing={1} alignItems="start">
                                <Grid item xs={12} md={3}>
                                    <Box display="grid">
                                        <Typography variant="button" fontWeight="bold">
                                            {`${item.first_name || ""} ${item.last_name || ""}`}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={9}>
                                    <Grid container spacing={1} alignItems="start">
                                        <Grid item xs={6} sm={valueNumber} md={valueNumber}>
                                            <Box display="grid">
                                                <Typography variant="caption" color="secondary">
                                                    {Strings.Common.BOOKING_HAND_BAGGAGE}
                                                </Typography>
                                                <Typography variant="button">
                                                    {getValueBaggage(dataMapFreeBaggage.find(el => `${el.is_hand_baggage}` === "true"))}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6} sm={valueNumber} md={valueNumber}>
                                            <Box display="grid">
                                                <Typography variant="caption" color="secondary">
                                                    {Strings.Common.BOOKING_CHECKED_BAGGAGE}
                                                </Typography>
                                                <Typography variant="button">
                                                    {getValueBaggage(dataMapFreeBaggage.find(el => `${el.is_hand_baggage}` === "false"))}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6} sm={valueNumber} md={valueNumber}>
                                            <Box display="grid">
                                                <Typography variant="caption" color="secondary">
                                                    {Strings.BOOKING.SEAT_NUMBER}
                                                </Typography>
                                                <Typography variant="button">
                                                    {"-"}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        {props.isShowReservationCode &&
                                            <Grid item xs={6} sm={valueNumber} md={valueNumber}>
                                                <Box display="grid">
                                                    <Typography variant="caption" color="secondary">
                                                        {Strings.BOOKING.TICKET_NUMBER}
                                                    </Typography>
                                                    <Typography variant="button">
                                                        {dataMapETickets.get(item.id)?.ticket_number || "-"}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    ))
                }
            </Grid>
        </Grid>
    );
};

export default CardItemFlightInfo;