import { useMemo } from "react";
import { Box, Typography } from "@maysoft/common-component-react";

import Helpers from "commons/helpers";
import { PaymentStatus } from "constants/enum";
import { ICodename } from "commons/interfaces";
import Strings from "constants/strings";



const CardPaymentBookingOrder = (props: {
    amount: number,
    currency: string,
    serviceFee: number,
    paymentFee: number,
    paymentMethod: number,
    paymentStatus: number,
    serviceFeeTax: number,
    paymentTypeList: ICodename[]
}) => {

    const isRefunded = useMemo(() => (
        [PaymentStatus.Refunded, PaymentStatus.WaitingRefund].includes(props?.paymentStatus)
    ), [props?.paymentStatus]);

    const paymentName = useMemo(() => {
        const item = props?.paymentTypeList.find(el => el.code === props?.paymentMethod);
        if (item) {
            return Strings.formatString(Strings.BOOKING_ORDER.DETAIL.PAYMENT_METHOD_TEXT, item?.name?.slice(0, 1).toLowerCase().concat(item?.name?.slice(1)));
        } else {
            return "-";
        }
    }, [props?.paymentMethod, props?.paymentTypeList])

    return (
        <Box sx={{
            gap: 1,
            display: "grid",
            padding: "16px",
            borderRadius: "16px",
            border: "1.5px solid #F0F0F0",
        }}>
            <Typography variant="h6">
                {Strings.BOOKING_ORDER.DETAIL.PAYMENT_INFORMATION}
            </Typography>
            <BoxTitleValue
                title={
                    <Typography variant="button">
                        {Strings.BOOKING_ORDER.DETAIL.TOTAL_TICKET_PRICE}
                    </Typography>
                }
                value={
                    <Typography variant="button" color={isRefunded ? "error" : "dark"}>
                        {isRefunded ? "- " : ""}
                        {`${Helpers.formatCurrency((props?.amount || 0) - (props?.serviceFee || 0) - (props?.paymentFee || 0))} `}
                        {props?.currency}
                    </Typography>
                }
            />

            <BoxTitleValue
                title={
                    <Typography variant="button">
                        {`${Strings.Common.SERVICE_FEE}:`}
                    </Typography>
                }
                value={
                    <Typography variant="button">
                        {`${Helpers.formatCurrency((props?.serviceFee || 0) - (props?.serviceFeeTax || 0))} `}
                        {props?.currency}
                    </Typography>
                }
            />

            <BoxTitleValue
                title={
                    <Typography variant="button">
                        {`${Strings.Common.TAX_OF_SERVICE_FEE}:`}
                    </Typography>
                }
                value={
                    <Typography variant="button">
                        {`${Helpers.formatCurrency(props?.serviceFeeTax || 0)} `}
                        {props?.currency}
                    </Typography>
                }
            />

            <BoxTitleValue
                title={
                    <Typography variant="button">
                        {`${Strings.BOOKING_ORDER.DETAIL.TOTAL_AMOUNT}:`}
                    </Typography>
                }
                value={
                    <Typography variant="button" fontWeight="bold" color={isRefunded ? "error" : "dark"}>
                        {isRefunded ? "- " : ""}
                        {`${Helpers.formatCurrency((props?.amount || 0) - (props?.paymentFee || 0))} `}
                        {props?.currency}
                    </Typography>
                }
            />

            <BoxTitleValue
                title={
                    <Typography variant="button">
                        {`${Strings.BOOKING.PHI_TIEN_ICH}:`}
                    </Typography>
                }
                value={
                    <Typography variant="button">
                        {`${Helpers.formatCurrency(props?.paymentFee || 0)} `}
                        {props?.currency}
                    </Typography>
                }
            />

            <BoxTitleValue
                title={
                    <Typography variant="button">
                        {`${Strings.BOOKING_ORDER.DETAIL.FINAL_PAYMENT_AMOUNT}:`}
                    </Typography>
                }
                value={
                    <Typography variant="button" fontWeight="bold">
                        {`${Helpers.formatCurrency(props?.amount || 0)} `}
                        {props?.currency}
                    </Typography>
                }
            />

            <BoxTitleValue
                title={
                    <Typography variant="button">
                        {`${Strings.Common.PAYMENT_TYPE}:`}
                    </Typography>
                }
                value={
                    <Typography variant="button">
                        {paymentName}
                    </Typography>
                }
            />
        </Box>
    )
};

export default CardPaymentBookingOrder;

const BoxTitleValue = (props: {
    title: JSX.Element,
    value: JSX.Element,
}) => (
    <Box sx={{
        gap: 1,
        display: "flex",
        flexWrap: "wrap",
        alignItems: "end",
        justifyContent: "space-between",
    }}>
        <Box sx={{ gap: 1, display: "grid" }}>
            {props.title}
        </Box>
        <Box sx={{ gap: 1, display: "grid" }}>
            {props.value}
        </Box>
    </Box>
);